/* eslint react-hooks/exhaustive-deps:0, react/forbid-prop-types:0, react/jsx-no-useless-fragment:0
*/
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  useNavigate,
  useLocation,
  Outlet,
} from 'react-router-dom';

import {
  H1 as RawH1,
  SmallButton as Button,
  CenteredSpinner,
  ExamineeMenu,
} from '../../components';

const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CenteredButton = styled(Button)`
  margin: auto;
`;

const Content = styled.div`
  margin-right: .1rem;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
`;

const H1 = styled(RawH1)`
  margin: 1rem;
`;

const P = styled.p`
  margin: 1rem 1rem 1rem 1rem;
`;

const Loading = () => (
  <>
    <H1>Please Wait</H1>
    <CenteredSpinner />
  </>
);

/**
 * A wrapper component for all the discover components
 * Loads the results
 *
 * Displays an error if the results are not finished,
 * or if the results are suppressed.
 */
const DiscoverComponent = ({
  activeProduct,
  allFinished,
  rankedClusters,
  loadResults,
  loading,
  unloadResults,
  manualDiscover,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  let shouldBlockLoading = true;
  if (
    location.pathname === '/dashboard/examinee/discover'
    || location.pathname === '/dashboard/examinee/discover/topthree'
  ) {
    shouldBlockLoading = false;
  }

  useEffect(() => {
    // loads there results once on mount
    loadResults(activeProduct).then((data) => {
      if (data && data.jobs && data.jobs.length >= 3 && !manualDiscover) {
        navigate('/dashboard/examinee/succeed', { replace: true });
      }
    });
    return () => {
      unloadResults();
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location?.pathname]);

  // block if loading
  if (shouldBlockLoading && (loading || rankedClusters.ranked.length === 0)) {
    return (
      <>
        <ExamineeMenu
          assigned={activeProduct}
        >
          <div>
            <Loading />
          </div>
        </ExamineeMenu>
      </>
    );
  }

  if (!allFinished || (!loading && rankedClusters.ranked.length === 0)) {
    return (
      <>
        <ExamineeMenu
          assigned={activeProduct}
        >
          <CenteredContainer>
            <H1>DISCOVER YOUR CAREER PROFILE</H1>
            <P>You must complete all your assessments in order to continue.</P>
            <CenteredButton
              to="/dashboard/examinee/explore"
            >
              Back
            </CenteredButton>
          </CenteredContainer>
        </ExamineeMenu>
      </>
    );
  }

  return (
    <>
      <ExamineeMenu
        assigned={activeProduct}
        id="content"
      >
        <Content>
          <H1>DISCOVER YOUR CAREER PROFILE</H1>
          <Outlet />
        </Content>
      </ExamineeMenu>
    </>
  );
};

DiscoverComponent.propTypes = {
  abilities: PropTypes.shape({
    ability: PropTypes.arrayOf(PropTypes.object),
    career: PropTypes.arrayOf(PropTypes.object),
  }),
  activeProduct: PropTypes.object,
  /** The clusters ranked in order of results */
  rankedClusters: PropTypes.shape({
    ranked: PropTypes.array,
  }),
  /*
  // all the clusters in order of cluster ordering (1-14)
  allClusters: PropTypes.object,
  favoriteJobs,
  interests,
  loadClusters,
  loadResults,
  loading,
  toggleFavoriteJob,
  type,
  unloadResults,
  updateFavoriteJob,
  user,
  values,
  */
};

DiscoverComponent.defaultProps = {
  abilities: {},
  activeProduct: {},
  rankedClusters: [],
};

export default DiscoverComponent;
