import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import { chain } from 'lodash';

import {
  Colors,
  ValuesGraph,
  MediumButton as Button,
} from '../../components';

import GraphTitle from './GraphTitle';

import {
  useResize,
} from '../../utils/sizing';

const GraphContainerDiv = styled.div`
  border-radius: 15px; 
  background-color: ${Colors.white};
  border: 1px solid ${Colors.grey};
  min-width: 500px;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 1rem 0;
`;

const RedText = styled.span`
  color: ${Colors.red};
  font-weight: bold;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;
`;

const P = styled.p`
  padding: 0;
  margin: .5rem 1.5rem;
`;

const ScaleName = styled.h3`
  font-weight: bold;
  font-size: 1rem;
  color: ${Colors.red};
  margin: 0rem 1.5rem;
`;

const ScaleDic = {
  accepting: 'You prefer to tackle situations with tried-and-true methods rather than experimenting on the fly. You tend to be most productive in steady, quiet work environments.',
  investigative: '"Why?" is one of your favorite questions. You thrive at work when you are able to gather information to put towards novel solutions to complex problems.',
  carefree: 'You appreciate a lighthearted, imaginative approach to work. You would rather spend time thinking about new ways to tackle problems than tidying up your workspace.',
  practical: 'You value an efficient workplace and have a deep appreciation for keeping things moving forward in an organized manner. ',
  conformity: 'When you are  at work, you flourish under clear-cut guidelines and parameters. Your best work gets done when you know exactly what you are working with.',
  independence: 'You prefer working to your own standards and on your own time. You flourish in flexible work environments where "open to interpretation" can be taken to heart.',
  supportive: 'You do not feel the need to bark orders at people. You are happy working towards a collective goal without the bureaucratic and social pressures attached to roles in leadership.',
  leadership: 'In large group situations, you often find yourself taking the reins. You thrive when you are able to step up, delegate tasks, and make final decisions.',
  flexibility: 'Sometimes too much planning just gets in the way. You would rather have more time to complete a task in a flexible manner than less time after carefully planning what to do. You value the freedom to tackle your work as it comes.',
  orderliness: 'You value work environments where tasks are outlined well in advance and everything you need is readily accessible. Executing a thoughtfully-designed plan brings you true satisfaction.',
  privacy: 'The work you complete is more important than the recognition you might receive later. You prefer to stay out of the spotlight, focusing instead on a job well done.',
  recognition: 'At work, everyone knows who you are. Being acknowledged for a job well done empowers you to work even harder. You value your own accomplishments, and your peers value you.',
  realistic: 'You tend to strive for "function over form." You can appreciate art, but not necessarily when you are at work. Utility and practicality are amongst your top workplace priorities.',
  aesthetic: 'The phrase "art for art\'s sake" rings true. You understand that art can often bring value to the workplace. Your favorite tasks involve the ability to incorporate an artistic sensibility.',
  reserved: 'Your best work gets done when you can hone in on individual projects and tend to your own affairs. You understand the value of teamwork, but independence will always be your preference.',
  social: 'At work, you hit your stride alongside others. It doesn’t matter if you are collaborating, offering help, or collaborating to offer help – teamwork invigorates you.',
};

const DiscoverValues = ({
  values,
}) => {
  const ref = useRef();
  const [ width ] = useResize(ref);
  const [ showReadMore, setShowReadMore ] = useState(false);
  const {
    percentile: percentiles,
    conversion: conversions,
  } = values;
  const ranked = chain(Array(conversions.length))
    .map((sc, i) => ({
      ...conversions[i],
      norm: undefined,
      scaleType: undefined,
      rawScore: parseInt(conversions[i].rawScore, 10),
      conversion: conversions[i].norm,
      percentile: percentiles[i].norm,
    }))
    .sortBy((a) => (-Math.abs(a.conversion)))
    .map((sc) => {
      if (sc.conversion <= 0) {
        return sc.left;
      }
      return sc.right;
    })
    .value();

  return (
    <GraphContainerDiv
      ref={ref}
    >
      <GraphTitle
        active="values"
      />
      <P>
        Our Values survey is designed to create a rough sketch of your &quot;work
        persona,&quot; calling attention to the job-related personal characteristics
        that mean the most to you. Your three strongest traits are:
      </P>
      <P><RedText>{ranked[0].toUpperCase()}</RedText>: {ScaleDic[ranked[0].toLowerCase()]}</P>
      <P><RedText>{ranked[1].toUpperCase()}</RedText>: {ScaleDic[ranked[1].toLowerCase()]}</P>
      <P><RedText>{ranked[2].toUpperCase()}</RedText>: {ScaleDic[ranked[2].toLowerCase()]}</P>
      <ValuesGraph
        values={values}
        width={width}
      />
      <P>
        Your score on each Value is represented by a bar in the graph. The
        further a bar is from the middle line in either direction, the higher
        your score is on that trait. Your three strongest traits are highlighted
        in <RedText>RED</RedText>. The graph represents your work values as
        compared to everyone who has completed this inventory. The further a
        score is from the middle, the further it is from the average response
        for that trait, and the more important that trait is to you.
      </P>
      <P>
        For detailed information on all sixteen Values,&nbsp;
        <Button
          link
          onClick={() => setShowReadMore(!showReadMore)}
        >
          click here
        </Button>.
      </P>
      {
        showReadMore && (
        <>
          <ScaleName>INVESTIGATIVE VERSUS ACCEPTING</ScaleName>
          <P>
            Intellectual curiosity and the challenge of solving a complex task
            are major values of persons scoring toward the Investigative end of
            this scale. The need for information, the need to know, the need to
            question “why,” is very important to such people. This value is
            first seen in very young children, and the importance of this value
            grows in different degrees into adulthood. Persons scoring toward
            this end of the scale value activities in which they work to satisfy
            their curiosity, find new solutions to problems, are mentally
            challenged and understand and interpret complex ideas. Persons
            scoring toward the Accepting end of this scale value and use good
            existing solutions rather than finding new methods. They do not need
            to solve complex problems and like to avoid complications in their
            work environment. Persons scoring toward this end of the scale use
            sound, established procedures to succeed in their work situations
            and prefer not to develop new tactics to solve problems.
          </P>
          <ScaleName>PRACTICAL VERSUS CAREFREE</ScaleName>
          <P>
            Showing proper appreciation for one’s personal things and
            appreciation of practical and efficient ways of doing things are
            major values of persons scoring toward the Practical end of this
            scale. Such persons value activities in which they take good care of
            their property, continually improve their abilities and work with
            things to make them more practical and efficient. They prefer to
            work in a hands-on situation using established technical knowledge.
            Persons scoring toward the Carefree end of this scale value
            activities where others take care of equipment and keep things in
            good working order. They can take a less diligent more lighthearted,
            imaginative approach to new ideas. They are more likely to take
            economic risks and try out new solutions in their work situation.
          </P>
          <ScaleName>INDEPENDENCE VERSUS CONFORMITY</ScaleName>
          <P>
            Independence from rules, regulations and social conventions, and the
            freedom to work on their own are major values of persons scoring
            toward the Independence end of this scale. Such persons value
            activities in which they do not have to follow orders but work to
            their own standards, they work on their own without direction, and
            are not restricted by social obligations. Persons scoring toward the
            Conformity end of this scale value working under careful supervision
            where clear directions and regulations can be followed. They
            carefully follow the clearly accepted standard set of principles in
            the workplace and prefer to depend on given plans and rules. They
            are often positive in their attitude toward following orders and
            working with other people.
          </P>
          <ScaleName>LEADERSHIP VERSUS SUPPORTIVE</ScaleName>
          <P>
            Making decisions, directing others, and speaking for the group are
            major values of persons scoring toward the Leadership end of this
            scale. Such persons have a need to be seen as the one who is in
            charge and usually take positions of leadership. Persons scoring
            toward this end of the scale value activities in which they make the
            decisions for the group and are chosen as a leader or decision
            maker. Persons scoring toward the Supportive end of this scale value
            activities in which they can be a good follower and do not need to
            direct others or tell others what to do. They prefer to carry out a
            plan of action and let others be the speaker for a group. They do
            not feel the need to exert authority over others but instead support
            the decisions of others.
          </P>
          <ScaleName>ORDERLINESS VERSUS FLEXIBILITY</ScaleName>
          <P>
            Orderliness and keeping things neat and in their proper place are
            major values of persons scoring toward the Orderliness end of this
            scale. Such persons value activities in which they keep their things
            neat and tidy and do what they are expected to do. Persons scoring
            toward the Flexibility end of this scale value activities in which
            they can take things as they come and do not need to keep things
            orderly and neat. They feel free to make a last minute decision or
            act when the need arises. There is no need to carefully follow an
            orderly plan. Instead their need is to be more flexible and less
            rigid toward fixed plans.
          </P>
          <ScaleName>RECOGNITION VERSUS PRIVACY</ScaleName>
          <P>
            To become well known and famous and to know important people are
            major values of persons scoring toward the Recognition end of this
            scale. Such persons seek admiration of others as well as the rewards
            of honorary degrees and of having their name in print. Persons
            scoring toward this end of the scale value activities in which they
            become well-known and in which they are looked up to by other
            people. Persons scoring toward the Privacy end of this scale value
            keeping their activities private and are not concerned with
            receiving honors or being considered a famous person. They prefer to
            work quietly either alone or with others and prefer to remain
            unnoticed and out of the spotlight. They may concentrate on what
            they do rather than the goal of becoming well-known.
          </P>
          <ScaleName>AESTHETIC VERSUS REALISTIC</ScaleName>
          <P>
            Artistic appreciation and the enjoyment of music and the arts are
            major values of persons scoring toward the Aesthetic end of this
            scale. Such persons value activities in which they appreciate beauty
            and artistic skill in the workplace. They show artistic and
            emotional sensitivity. They like to arrange their workspace to look
            good. If they are working in an artistic field the looks of an item
            are more important than the practical more realistic use of an item.
            Persons scoring toward the Realistic end of this scale value
            activities in which functionality is more important than artistic
            form in their work. While they may have an appreciation of art they
            do not rely on their artistic senses or intuition in the workplace.
            They are more concerned with the practical usefulness of their work
            rather than its artistic merit.
          </P>
          <ScaleName>SOCIAL VERSUS RESERVED</ScaleName>
          <P>
            Helping others and appreciating the work of charitable service
            groups are major values of persons scoring toward the Social end of
            this scale. Working with people in a friendly situation is important
            to such persons. Persons scoring toward this end of the scale value
            activities in which they work for the good of others, meet and get
            to know people, and are understanding of those who have
            difficulties. They like to work as a team and have a lot of
            interaction with others. Persons scoring toward the Reserved end of
            this scale value activities in which they spend time on their own
            projects and tend to their own affairs. They prefer to work more
            independently without assistance and value privacy and limited
            interaction with others. They like to keep their social life apart
            from their work.
          </P>
        </>
        )
      }
      <ButtonContainer>
        <Button to="/dashboard/examinee/discover/interests">
          Back
        </Button>
        <Button to="/dashboard/examinee/discover/abilities">
          Next
        </Button>
      </ButtonContainer>
    </GraphContainerDiv>
  );
};

export default DiscoverValues;
