import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as d3 from 'd3';
import { isFinite } from 'lodash';

import {
  rem,
} from '../../utils/sizing';

import * as Colors from '../components/Colors';

const ChartLabel = styled.g`
  font-size: 1rem;
  font-weight: bold;
  color: ${Colors.grey};
`;

const FullGraph = ({
  clusters,
  width: inWidth,
  clusterMax,
  showAxis,
}) => {
  let width = inWidth;
  if (inWidth < 500) {
    width = 500;
  }
  const [labelWidth, setLabelWidth] = React.useState(282);
  const margin = {
    left: rem(2) + labelWidth,
    right: rem(2),
    top: rem(1),
    bottom: rem(1),
    labelPadding: rem(0.5),
  };
  const barHeight = rem(2);
  const barPadding = 0.1;
  const height = clusters.length * (barHeight + barPadding) + margin.top + margin.bottom;

  React.useEffect(() => {
    margin.left = rem(1) + labelWidth;
  }, [labelWidth]);

  const x = d3
    .scaleLinear()
    .domain([0, clusterMax])
    .range([margin.left, width - margin.right ]);

  const y = d3
    .scaleBand()
    .domain(clusters.map((c) => (c.name)))
    .range([margin.top, height - margin.bottom])
    .padding(barPadding);

  const yAxis = d3
    .axisLeft(y)
    .tickSize(0)
    .tickFormat((d) => (d.toUpperCase()));
  const bottomAxis = d3
    .axisBottom(x)
    .tickFormat((d) => (Math.floor((d / clusterMax) * 100)));
  /*
  const topAxis = d3.axisTop(x).tickFormat((d) => (Math.floor((d / clusterMax) * 100)));
  */

  const G = clusters.map((c) => {
    let delay = 0;
    const duration = 1000;
    const startInterests = 0;
    const endInterests = (c.interests || 0) + startInterests;
    const startValues = c.interests || 0;
    const endValues = (c.values || 0) + startValues;
    const startAbilities = startValues + (c.values || 0);
    const endAbilities = (c.abilities || 0) + startAbilities;
    const startText = (c.values || 0) + (c.interests || 0) + (c.abilities || 0);

    const RectStyle = {
      stroke: Colors.grey,
      strokeWidth: 1,
    };

    return (
      <g
        key={`graph_${c.ranking}`}
      >
        {
          c.interests ? (
            <rect
              ref={(node) => {
                let tmp = x(endInterests) - x(startInterests);
                if (tmp < 0) {
                  tmp = 1;
                }
                d3
                  .select(node)
                  .transition()
                  .ease(d3.easeLinear)
                  .duration(duration)
                  .attr('width', tmp);
                delay += duration;
              }}
              x={x(startInterests)}
              y={y(c.name)}
              width={0}
              height={y.bandwidth()}
              fill={Colors.blue}
              style={RectStyle}
            />
          ) : ''
        }
        {
          c.values ? (
            <rect
              ref={(node) => {
                let tmp = x(endValues) - x(startValues);
                if (tmp < 0) {
                  tmp = 1;
                }
                d3
                  .select(node)
                  .transition()
                  .delay(delay)
                  .ease(d3.easeLinear)
                  .duration(duration)
                  .attr('width', tmp);
                delay += duration;
              }}
              x={x(startValues)}
              y={y(c.name)}
              width={0}
              height={y.bandwidth()}
              fill={Colors.red}
              style={RectStyle}
            />
          ) : ''
        }
        {
          c.abilities ? (
            <rect
              ref={(node) => {
                let tmp = x(endAbilities) - x(startAbilities);
                if (tmp < 1 || !isFinite(tmp)) {
                  tmp = 1;
                }
                d3
                  .select(node)
                  .transition()
                  .delay(delay)
                  .ease(d3.easeLinear)
                  .duration(duration)
                  .attr('width', tmp);
                delay += duration;
              }}
              x={x(startAbilities)}
              y={y(c.name)}
              width={0}
              height={y.bandwidth()}
              fill={Colors.green}
              style={RectStyle}
            />
          ) : ''
        }
        <text
          ref={(node) => {
            d3
              .select(node)
              .transition()
              .delay(delay)
              .duration(duration)
              .attr(
                'opacity',
                1,
              );
            if (node) {
              const bbox = node.getBBox();
              if (x(startText) - bbox.width - rem(0.25) > margin.left) {
                d3
                  .select(node)
                  .attr(
                    'x',
                    x(startText)
                    - bbox.width
                    - rem(0.25),
                  )
                  .style(
                    'fill',
                    Colors.white,
                  );
              } else {
                d3
                  .select(node)
                  .attr(
                    'x',
                    x(0),
                  )
                  .style(
                    'fill',
                    Colors.grey,
                  );
              }
            }
            delay += duration;
          }}
          opacity="0"
          x={x(startText)}
          y={y(c.name) + rem(1)}
        >
          {Math.floor((c.total / clusterMax) * 100)}%
        </text>
      </g>
    );
  });

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
    >
      <ChartLabel
        fill={Colors.grey}
        ref={(node) => {
          d3
            .select(node)
            .call(yAxis)
            .select('.domain')
            .attr('display', 'none');

          if (node) {
            const w = node.getBBox().width;
            if (labelWidth < w) {
              setLabelWidth(w);
            }
          }
        }}
        transform={`translate(${margin.left - margin.labelPadding},0)`}
      />
      {G}
      {
        showAxis && (
          <g ref={(node) => d3.select(node).call(bottomAxis)} transform={`translate(0,${height - margin.bottom})`} />
        )
      }
    </svg>
  );
};

FullGraph.propTypes = {
  clusters: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    values: PropTypes.number,
    interests: PropTypes.number,
    abilities: PropTypes.number,
    ranking: PropTypes.number,
  })),
  width: PropTypes.number,
  clusterMax: PropTypes.number.isRequired,
};
FullGraph.defaultProps = {
  clusters: [],
  width: 500,
};

export default FullGraph;
