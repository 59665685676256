/* eslint no-console:0 */
import moment from 'moment';
import { dispatchWrapper as sendIt } from '../utils/shortHand';
import http from '../utils/httpCli';

export const INIT_USER_REQUEST = 'INIT_USER_REQUEST';
export const INIT_USER_SUCCESS = 'INIT_USER_SUCCESS';
export const INIT_USER_ERROR = 'INIT_USER_ERROR';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';

export const PASSWORD_REQUEST = 'PASSWORD_REQUEST';
export const PASSWORD_SUCCESS = 'PASSWORD_SUCCESS';
export const PASSWORD_ERROR = 'PASSWORD_ERROR';

export const ENTER_PASSCODE_REQUEST = 'ENTER_PASSCODE_REQUEST';
export const ENTER_PASSCODE_SUCCESS = 'ENTER_PASSCODE_SUCCESS';
export const ENTER_PASSCODE_ERROR = 'ENTER_PASSCODE_ERROR';

export const initUser = (id) => (
  (dispatch) => {
    sendIt(dispatch, INIT_USER_REQUEST);
    return http.get(`/user/${id}?full=1`).then(({ data }) => {
      sendIt(dispatch, INIT_USER_SUCCESS, data);
      return data;
    }).catch((error) => {
      console.error(error);
      if (error?.response) {
        sendIt(dispatch, INIT_USER_ERROR, { error: error.response });
      } else {
        sendIt(dispatch, INIT_USER_ERROR, { error });
      }
    });
  }
);

export const enterPasscode = (passcode) => (
  (dispatch) => {
    sendIt(dispatch, ENTER_PASSCODE_REQUEST);
    return http.post('/passcode', { passcode }).then(({ data }) => {
      sendIt(dispatch, ENTER_PASSCODE_SUCCESS, data);
      return data;
    }).catch((e) => {
      if (e.response && (e.response.status === 400 || e.response.status === 404)) {
        sendIt(dispatch, ENTER_PASSCODE_ERROR, {
          message: e.response.data.message,
        });
        return Promise.reject(new Error(e.response.data.message));
      }
      throw e;
    });
  }
);

/** update the user values. Cannot contain the password. Must include the id */
export const updateUser = (values) => (
  (dispatch) => {
    sendIt(dispatch, UPDATE_USER_REQUEST);
    return http.post(`/user/${values.id}`, {
      ...values,
      grade: values.grade ? parseInt(values.grade, 10) : undefined,
      dob: moment.isMoment(values.dob) ? values.dob.format('YYYY-MM-DD') : undefined,
    }).then(() => {
      sendIt(dispatch, UPDATE_USER_SUCCESS, values);
    }).catch((e) => {
      sendIt(dispatch, UPDATE_USER_ERROR, e);
      return Promise.reject(e);
    });
  }
);

export const updatePassword = (values) => (
  (dispatch) => {
    sendIt(dispatch, PASSWORD_REQUEST);
    return http.post('/password', {
      ...values,
    }).then(() => {
      sendIt(dispatch, PASSWORD_SUCCESS);
    }).catch(({ response }) => {
      if (response.status === 400) {
        sendIt(dispatch, PASSWORD_ERROR, { message: response.data.message });
      }
    });
  }
);
