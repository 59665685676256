import React from 'react';
import styled from 'styled-components';
import * as d3 from 'd3';

import {
  rem,
} from '../../utils/sizing';

import * as Colors from './Colors';

const ChartLabel = styled.g`
  font-size: 1rem;
  font-weight: bold;
`;

const getName = (row) => (row.name);

const AbilityCareerProfile = ({
  abilities,
  width: inWidth,
}) => {
  const {
    career,
  } = abilities;

  const [labelWidth, setLabelWidth] = React.useState(282);

  let width = inWidth;
  if (inWidth === 0) {
    width = 500;
  }

  const margin = {
    left: rem(1),
    right: rem(1.25),
    top: rem(1),
    bottom: rem(1),
  };
  const barHeight = rem(2);
  const barPadding = 0.1;
  const height = career.length * (barHeight + barPadding) + margin.top + margin.bottom;
  const delay = 0;
  const duration = 1000;

  const x = d3
    .scaleLinear()
    .domain([0, 100])
    .range([margin.left + labelWidth, width - margin.right]);

  const y = d3
    .scaleBand()
    .domain(career.map((s) => (getName(s))))
    .rangeRound([margin.bottom, height - margin.top])
    .padding(barPadding);

  const bottomAxis = d3.axisBottom(x);
  const yAxis = d3
    .axisLeft(y)
    .tickFormat((d) => (d.toUpperCase()))
    .tickSize(0);

  const G = career.map((c) => (
    <g
      key={`careerProfile_${c.ranking}`}
    >
      {
        c.cutoffs.map((d, i) => (
          <rect
            key={`cutoff_${d}`}
            opacity={1 - (i / c.cutoffs.length)}
            x={x(c.cutoffs[i - 1] || 0)}
            y={y(getName(c))}
            width={x(d) - x(i - 1 >= 0 ? c.cutoffs[i - 1] : 0)}
            height={y.bandwidth()}
            fill={Colors.green}
          />
        ))
      }
      <circle
        ref={(node) => {
          d3
            .select(node)
            .transition()
            .delay(delay)
            .ease(d3.easeLinear)
            .duration(duration)
            .attr(
              'cx',
              x(c.norm),
            );
        }}
        cx={x(0)}
        cy={y(getName(c)) + y.bandwidth() / 2}
        r={rem(0.5)}
        fill={
          c.plus ? Colors.yellow : Colors.grey
        }
      />
      {/*
      <text
        ref={(node) => {
          d3
            .select(node)
            .transition()
            .delay(delay)
            .duration(duration)
            .attr(
              'opacity',
              1,
            );
          if (node) {
            const bbox = node.getBBox();
            if (x(c.norm) - bbox.width - rem(0.25) > margin.left) {
              d3
                .select(node)
                .attr(
                  'x',
                  x(c.norm)
                  - bbox.width
                  - rem(0.25),
                )
                .style(
                  'fill',
                  Colors.white,
                );
            } else {
              d3
                .select(node)
                .attr(
                  'x',
                  x(0),
                )
                .style(
                  'fill',
                  Colors.grey,
                );
            }
          }
        }}
        opacity="0"
        x={x(c.norm)}
        y={y(c.name) + rem(0.889)}
      >
        {c.norm}
      </text>
        */}
    </g>
  ));

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
    >
      <ChartLabel
        ref={(node) => {
          d3
            .select(node)
            .call(yAxis)
            .select('.domain')
            .attr('display', 'none');

          if (node) {
            const w = node.getBBox().width;
            if (labelWidth < w) {
              setLabelWidth(w);
            }
          }
        }}
        transform={`translate(${labelWidth + margin.left},0)`}
      />
      {G}
      <g
        ref={(node) => d3.select(node).call(bottomAxis)}
        transform={`translate(0, ${height - margin.bottom})`}
      />
    </svg>
  );
};

export default AbilityCareerProfile;
