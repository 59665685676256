import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTable } from 'react-table';
import { cloneDeep, find } from 'lodash';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Link } from 'react-router-dom';

import DiscoverClusters from '../containers/DiscoverClusters';

import {
  MediumButton,
  XButton,
  Colors,
  ScrollToSelf,
} from '../../components';

const Container = styled.div`
  p {
    margin: 0;
  }
`;

const Content = styled.div`
  background-color: ${Colors.white};
  padding: 2rem;

  border: 1px solid ${Colors.grey};
  border-radius: 15px;
`;
const Table = styled.table`
  margin: auto;
  border-collapse: collapse;
`;
const Thr = styled.tr`
  border-bottom: 3px solid ${Colors.lightGrey};
`;
const Th = styled.th`
`;
const Td = styled.td`
  padding: 1em;
`;
const CTr = styled.tr`
  border-bottom: 1px solid ${Colors.black};
  &:hover {
    background-color: ${Colors.lightBlue};
  }
`;
const CTd = styled.td`
  text-align: center;
  padding: 1em;
`;
const Button = styled(MediumButton)`
  margin-top: 1rem !important;
`;
const Select = styled.select`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: ${Colors.white};
  color: ${Colors.grey};
  width: 100%;
  padding: .5rem 1rem;
`;

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = cloneDeep(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result.map((j, i) => ({
    ...j,
    favoriteJob: i,
  }));
};

const FavoriteInput = (params) => {
  const {
    value: initialValue,
    data: favoriteJobs,
    row: {
      original: job,
    },
    updateFavoriteJob,
  } = params;

  const [ value, setValue ] = useState(initialValue);
  useEffect(() => {
    setValue(initialValue);
  }, [ initialValue, favoriteJobs ]);

  const onChange = (e) => {
    setValue(e.target.value);
    const newPos = parseInt(e.target.value, 10);
    const newJobs = reorder(favoriteJobs, job.favoriteJob, newPos);
    updateFavoriteJob({
      favoriteJobs: newJobs,
    });
  };

  return (
    <Select value={value} onChange={onChange} data-testid="jobsorter-select-position">
      {
        favoriteJobs.map((d, i) => (
          <option value={i} key={`option_${d.id}`}>
            {i + 1}
            &nbsp;
            &#9662;
          </option>
        ))
      }
    </Select>
  );
};

const RemoveJobColumn = ({
  tglFavoriteJob,
  row: {
    original: job,
  },
}) => (
  <XButton
    onClick={() => tglFavoriteJob({ job })}
    screenReader="remove job"
  />
);

const JobTitleColumn = ({
  row: {
    original: job,
  },
}) => ((
  <Link
    to={`/job/${job.id}`}
  >
    {job.jobTitle}
  </Link>
));

const JobSorter = ({
  professional,
  favoriteJobs = [],
  toggleFavoriteJob,
  updateFavoriteJob,
}) => {
  const columns = React.useMemo(
    () => {
      const clusterType = professional ? 'professional' : 'cluster';
      const cols = [
        {
          Header: '',
          accessor: 'removeJob',
          Cell: RemoveJobColumn,
        },
        {
          Header: 'FAVORITE',
          accessor: 'favoriteJob',
          Cell: FavoriteInput,
        },
        {
          Header: 'NAME',
          accessor: 'jobTitle',
          Cell: JobTitleColumn,
        },
        {
          Header: 'CLUSTER',
          accessor: (row) => {
            const clust = find(row.clusters, { type: clusterType });
            if (clust) {
              return clust.name;
            }
            return '';
          },
        },
      ];
      if (!professional) {
        cols.push(
          {
            Header: 'SUB-CLUSTER',
            accessor: (row) => (row.subClusters[0] ? row.subClusters[0].name : ''),
          },
        );
      }
      return cols;
    },
    [professional],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: favoriteJobs,
    updateFavoriteJob,
    tglFavoriteJob: ({ job }) => toggleFavoriteJob({ job, favoriteJobs }),
  });

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(
      favoriteJobs,
      result.source.index,
      result.destination.index,
    );
    updateFavoriteJob({
      favoriteJobs: items,
    });
  };

  if (favoriteJobs.length === 0) {
    return (
      <Container>
        <Content>
          <p>
            You must select at least two jobs to sort them.
          </p>
        </Content>
      </Container>
    );
  }

  return (
    <Container>
      <ScrollToSelf />
      <p>
        Now that you have explored careers. Go head and sort your careers from
        your favorite to least favorite.

        You can drag and drop the careers using your mouse, or use the drop
        down. Remember, number one is your favorite job!

        When you are finished, click the finish button at the bottom of your list.
      </p>
      <Content>
        <DragDropContext
          onDragEnd={onDragEnd}
        >
          <Droppable
            droppableId="dorppable"
          >
            {(provided) => (
              <Table
                {...getTableProps()}
                ref={provided.innerRef}
              >
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <Thr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <Th {...column.getHeaderProps()}>{column.render('Header')}</Th>
                      ))}
                    </Thr>
                  ))}
                </thead>
                <tbody
                  {...getTableBodyProps()}
                >
                  {rows.map((row, index) => {
                    prepareRow(row);
                    return (
                      <Draggable
                        key={row.id}
                        draggableId={row.id}
                        index={index}
                      >
                        {(pro, snap) => (
                          <CTr
                            data-testid="jobsorter-row"
                            {...row.getRowProps()}
                            {...pro.draggableProps}
                            {...pro.dragHandleProps}
                            ref={pro.innerRef}
                          >
                            {
                              row.cells.map((cell) => {
                                if (cell.column.id === 'favoriteJob') {
                                  return (
                                    <CTd
                                      {...cell.getCellProps()}
                                      isdragging={snap.isDragging ? true : undefined}
                                    >
                                      {cell.render('Cell')}
                                    </CTd>
                                  );
                                }
                                return (
                                  <Td
                                    {...cell.getCellProps()}
                                    isdragging={snap.isDragging ? true : undefined}
                                  >
                                    {cell.render('Cell')}
                                  </Td>
                                );
                              })
                            }
                          </CTr>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </tbody>
              </Table>
            )}
          </Droppable>
        </DragDropContext>
        <Button
          style={{
            margin: 'auto',
          }}
          to="/dashboard/examinee/succeed"
        >
          Finish
        </Button>
      </Content>
    </Container>
  );
};

JobSorter.propTypes = {
  /** An array of favorite jobs */
  favoriteJobs: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    jobTitle: PropTypes.string,
    cluster: PropTypes.string,
    favoriteJob: PropTypes.number,
  })),
  /** A function to remove the job from your favorites. Called with job object as parameter. */
  toggleFavoriteJob: PropTypes.func.isRequired,
  /** A function to update the order of your favorite jobs.
   *  Called with `({ favoriteJobs: arrayOfJobs })`.
   */
  updateFavoriteJob: PropTypes.func.isRequired,
};

JobSorter.defaultProps = {
  favoriteJobs: [],
};

export default JobSorter;
