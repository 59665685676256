import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faUsers,
  faUserCircle,
  faCaretRight,
  faCaretDown,
  faCaretLeft,
  faCaretUp,
  faHome,
  faUniversity,
  faShoppingBag,
  faUserPlus,
  faCheck,
  faPrint,
  faStar as solidStar,
  faPlus,
  faX,
  faRandom,
  faDollarSign,
  faEllipsisV,
  faUpload,
  faCloudArrowDown,
  faChartLine,
  faPlayCircle,
  faAsterisk,
  faChartColumn,
  faTrash,
  faSearchPlus,
  faSearchMinus,
  faMinus,
  faFileLines,
  faLock,
  faEye,
  faEyeSlash,
  faEdit,
  faGear,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';

import {
  faStar as emptyStar,
} from '@fortawesome/free-regular-svg-icons';

import Explore from './ExploreIcon';
import Discover from './DiscoverIcon';
import Succeed from './SucceedIcon';

export {
  Explore,
  Discover,
  Succeed,
};

const buildIcon = (icon) => (
  ({ ...rest }) => (<FontAwesomeIcon icon={icon} {...rest} />)
);

export const Examinee = buildIcon(faUser);
export const Examinees = buildIcon(faUsers);
export const ExamineeCircle = buildIcon(faUserCircle);
export const AddExaminee = buildIcon(faUserPlus);

export const UpArrow = buildIcon(faCaretUp);
export const LeftArrow = buildIcon(faCaretLeft);
export const RightArrow = buildIcon(faCaretRight);
export const DownArrow = buildIcon(faCaretDown);

export const Home = buildIcon(faHome);
export const Admins = buildIcon(faUniversity);
export const Purchase = buildIcon(faShoppingBag);

export const Edit = buildIcon(faEdit);
export const Check = buildIcon(faCheck);
export const Printer = buildIcon(faPrint);
export const Star = buildIcon(solidStar);
export const EmptyStar = buildIcon(emptyStar);
export const Plus = buildIcon(faPlus);
export const X = buildIcon(faX);
export const Upload = buildIcon(faUpload);
export const Download = buildIcon(faCloudArrowDown);
export const Delete = buildIcon(faTrash);
export const Search = buildIcon(faSearchPlus);
export const CloseSearch = buildIcon(faSearchMinus);
export const Minus = buildIcon(faMinus);
export const Assign = buildIcon(faFileLines);
export const Lock = buildIcon(faLock);
export const Eye = buildIcon(faEye);
export const EyeSlash = buildIcon(faEyeSlash);

export const Move = buildIcon(faRandom);
export const DollarSign = buildIcon(faDollarSign);
export const VerticalMenu = buildIcon(faEllipsisV);

export const New = buildIcon(faAsterisk);
export const Resume = buildIcon(faPlayCircle);
export const Results = buildIcon(faChartColumn);
export const Pending = buildIcon(faChartLine);

export const Caution = buildIcon(faTriangleExclamation);

export const Settings = buildIcon(faGear);
