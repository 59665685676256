import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';

import {
  LOAD_PURCHASES_REQUEST,
  LOAD_PURCHASES_SUCCESS,
  LOAD_PURCHASES_ERROR,
} from '../actions/purchaseActions';

const PurchaseReducer = (
  state = {
    list: [],
    total: 0,
    loading: false,
  },
  action,
) => {
  const newState = cloneDeep(state);
  switch (action.type) {
    case LOAD_PURCHASES_REQUEST: {
      newState.loading = true;
      return newState;
    }
    case LOAD_PURCHASES_SUCCESS: {
      newState.list = action.payload.list.map((e) => ({
        ...e,
        updatedAt: DateTime.fromISO(e.updatedAt),
        createdAt: DateTime.fromISO(e.createdAt),
      }));
      newState.total = action.payload.total;
      newState.loading = false;
      return newState;
    }
    case LOAD_PURCHASES_ERROR: {
      newState.list = [];
      newState.total = 0;
      newState.loading = false;
      return newState;
    }
    default:
      return state;
  }
};

export default PurchaseReducer;
