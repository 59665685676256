/* eslint max-len:0 */
import React, { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import {
  Link,
} from 'react-router-dom';

import {
  CareerWheel,
  Colors,
  Clusters,
  MediumButton as Button,
} from '../../components';

const ANIMATION_DURATION = '0.25s';

const P = styled.p`
  margin: .25rem 1rem;
  padding: 0;
`;

const Content = styled.div`
  border-radius: 15px; 
  background-color: ${Colors.white};
  border: 1px solid ${Colors.grey};
  min-width: 500px;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 1rem;
`;

const WheelContainer = styled.div`
  display: flex;
  ${(props) => (props.showCluster ? css`flex: auto` : css`flex: 0`)};
  flex-direction: row;
`;

const StyledCareerWheel = styled(CareerWheel)`
  margin: auto;
`;

const ClusterContainer = styled.div`
  display: flex;
  ${(props) => (props.showCluster ? css`flex: auto; flex-basis:50%;` : css`flex: 0;`)}
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  max-width: 50%;
  transition: flex ${ANIMATION_DURATION} ease-out;
`;

const fadeIn = keyframes`
  from {
    transform: scale(.25);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;
const ClusterDetails = styled.div`
  ${(props) => {
    if (props.shouldAnimate && props.showCluster) {
      return css`animation: ${fadeIn} ${ANIMATION_DURATION} step-end;`;
    }
    if (props.showCluster) {
      return css`display: block;`;
    }
    return css`display: none;`;
  }}
  overflow: hidden;
  max-height: 600px;
`;

const ButtonRow = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin-top: 1rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: center;
`;

const ClusterTitle = styled.span`
  color: ${(props) => props.color};
  font-weight: bold;
`;

/*
* A welcome message for the discover page
*/
const DiscoverWelcome = () => {
  const [ showCluster, setShowCluster ] = useState(-1);
  const [ shouldAnimate, setShouldAnimate ] = useState(true);
  const [ clusterHighlight, setClusterHighlight ] = useState();
  useEffect(() => {
    switch (showCluster) {
      case Clusters.SHORT.SCIENCE:
        setClusterHighlight([{ ranking: Clusters.SCIENCE_PROFESSIONAL + 1 }]);
        break;
      case Clusters.SHORT.TECHNOLOGY:
        setClusterHighlight([{ ranking: Clusters.TECHNOLOGY_PROFESSIONAL + 1 }]);
        break;
      case Clusters.SHORT.OUTDOOR:
        setClusterHighlight([{ ranking: Clusters.OUTDOOR + 1 }]);
        break;
      case Clusters.SHORT.BUSINESS:
        setClusterHighlight([{ ranking: Clusters.BUSINESS_PROFESSIONAL + 1 }]);
        break;
      case Clusters.SHORT.CLERICAL:
        setClusterHighlight([{ ranking: Clusters.CLERICAL + 1 }]);
        break;
      case Clusters.SHORT.COMMUNICATION:
        setClusterHighlight([{ ranking: Clusters.COMMUNICATION + 1 }]);
        break;
      case Clusters.SHORT.ARTS:
        setClusterHighlight([{ ranking: Clusters.ARTS_PROFESSIONAL + 1 }]);
        break;
      case Clusters.SHORT.SERVICE:
        setClusterHighlight([{ ranking: Clusters.SERVICE_PROFESSIONAL + 1 }]);
        break;
      default:
        setClusterHighlight(undefined);
        break;
    }
  }, [ showCluster ]);
  return (
    <Content>
      <P>
        Congratulations! You have completed the assessment portion of the VIA
        System. Now, we are going to help you discover careers based on your
        Values, Interests, and Abilities. As part of the discovery process, we are
        going to refer you to groupings of similar careers called Career
        Clusters.
      </P>
      <P>
        The VIA System Career Clusters are depicted on the wheel below. Click on
        any of the clusters for a brief description.
      </P>
      <WheelContainer
        showCluster={showCluster >= 0}
      >
        <StyledCareerWheel
          height="600px"
          width="600px"
          rankedClusters={clusterHighlight}
          onClusterClick={(cluster) => {
            if (cluster === showCluster) {
              setShowCluster(-1);
              setShouldAnimate(true);
            } else {
              if (showCluster > -1) {
                setShouldAnimate(false);
              }
              setShowCluster(cluster);
            }
          }}
          fadeDuration="0.001s"
        />
        <ClusterContainer
          showCluster={showCluster >= 0}
        >
          <ClusterDetails
            showCluster={showCluster === Clusters.SHORT.SCIENCE}
            shouldAnimate={shouldAnimate}
          >
            <P>
              <ClusterTitle
                color={Clusters.COLORS[Clusters.SCIENCE_PROFESSIONAL]}
              >
                {Clusters.NAMES[Clusters.SCIENCE_PROFESSIONAL].toUpperCase()}
              </ClusterTitle>
              &nbsp;{Clusters.DESCRIPTIONS[Clusters.SCIENCE_PROFESSIONAL]}
            </P>
            <P>
              <ClusterTitle
                color={Clusters.COLORS[Clusters.SCIENCE_SKILLED]}
              >
                {Clusters.NAMES[Clusters.SCIENCE_SKILLED].toUpperCase()}
              </ClusterTitle>
              &nbsp;{Clusters.DESCRIPTIONS[Clusters.SCIENCE_SKILLED]}
            </P>
          </ClusterDetails>
          <ClusterDetails
            showCluster={showCluster === Clusters.SHORT.TECHNOLOGY}
            shouldAnimate={shouldAnimate}
          >
            <P>
              <ClusterTitle
                color={Clusters.COLORS[Clusters.TECHNOLOGY_PROFESSIONAL]}
              >
                {Clusters.NAMES[Clusters.TECHNOLOGY_PROFESSIONAL].toUpperCase()}
              </ClusterTitle>
              &nbsp;{Clusters.DESCRIPTIONS[Clusters.TECHNOLOGY_PROFESSIONAL]}
            </P>
            <P>
              <ClusterTitle
                color={Clusters.COLORS[Clusters.TECHNOLOGY_SKILLED]}
              >
                {Clusters.NAMES[Clusters.TECHNOLOGY_SKILLED].toUpperCase()}
              </ClusterTitle>
              &nbsp;{Clusters.DESCRIPTIONS[Clusters.TECHNOLOGY_SKILLED]}
            </P>
            <P>
              <ClusterTitle
                color={Clusters.COLORS[Clusters.CONSUMER_ECONOMICS]}
              >
                {Clusters.NAMES[Clusters.CONSUMER_ECONOMICS].toUpperCase()}
              </ClusterTitle>
              &nbsp;{Clusters.DESCRIPTIONS[Clusters.CONSUMER_ECONOMICS]}
            </P>
          </ClusterDetails>
          <ClusterDetails
            showCluster={showCluster === Clusters.SHORT.OUTDOOR}
            shouldAnimate={shouldAnimate}
          >
            <P>
              <ClusterTitle
                color={Clusters.COLORS[Clusters.OUTDOOR]}
              >
                {Clusters.NAMES[Clusters.OUTDOOR].toUpperCase()}
              </ClusterTitle>
              &nbsp;{Clusters.DESCRIPTIONS[Clusters.OUTDOOR]}
            </P>
          </ClusterDetails>
          <ClusterDetails
            showCluster={showCluster === Clusters.SHORT.BUSINESS}
            shouldAnimate={shouldAnimate}
          >
            <P>
              <ClusterTitle
                color={Clusters.COLORS[Clusters.BUSINESS_PROFESSIONAL]}
              >
                {Clusters.NAMES[Clusters.BUSINESS_PROFESSIONAL].toUpperCase()}
              </ClusterTitle>
              &nbsp;{Clusters.DESCRIPTIONS[Clusters.BUSINESS_PROFESSIONAL]}
            </P>
            <P>
              <ClusterTitle
                color={Clusters.COLORS[Clusters.BUSINESS_SKILLED]}
              >
                {Clusters.NAMES[Clusters.BUSINESS_SKILLED].toUpperCase()}
              </ClusterTitle>
              &nbsp;{Clusters.DESCRIPTIONS[Clusters.BUSINESS_SKILLED]}
            </P>
          </ClusterDetails>
          <ClusterDetails
            showCluster={showCluster === Clusters.SHORT.CLERICAL}
            shouldAnimate={shouldAnimate}
          >
            <P>
              <ClusterTitle
                color={Clusters.COLORS[Clusters.CLERICAL]}
              >
                {Clusters.NAMES[Clusters.CLERICAL].toUpperCase()}
              </ClusterTitle>
              &nbsp;{Clusters.DESCRIPTIONS[Clusters.CLERICAL]}
            </P>
          </ClusterDetails>
          <ClusterDetails
            showCluster={showCluster === Clusters.SHORT.COMMUNICATION}
            shouldAnimate={shouldAnimate}
          >
            <P>
              <ClusterTitle
                color={Clusters.COLORS[Clusters.COMMUNICATION]}
              >
                {Clusters.NAMES[Clusters.COMMUNICATION].toUpperCase()}
              </ClusterTitle>
              &nbsp;{Clusters.DESCRIPTIONS[Clusters.COMMUNICATION]}
            </P>
          </ClusterDetails>
          <ClusterDetails
            showCluster={showCluster === Clusters.SHORT.ARTS}
            shouldAnimate={shouldAnimate}
          >
            <P>
              <ClusterTitle
                color={Clusters.COLORS[Clusters.ARTS_PROFESSIONAL]}
              >
                {Clusters.NAMES[Clusters.ARTS_PROFESSIONAL].toUpperCase()}
              </ClusterTitle>
              &nbsp;{Clusters.DESCRIPTIONS[Clusters.ARTS_PROFESSIONAL]}
            </P>
            <P>
              <ClusterTitle
                color={Clusters.COLORS[Clusters.ARTS_SKILLED]}
              >
                {Clusters.NAMES[Clusters.ARTS_SKILLED].toUpperCase()}
              </ClusterTitle>
              &nbsp;{Clusters.DESCRIPTIONS[Clusters.ARTS_SKILLED]}
            </P>
          </ClusterDetails>
          <ClusterDetails
            showCluster={showCluster === Clusters.SHORT.SERVICE}
            shouldAnimate={shouldAnimate}
          >
            <P>
              <ClusterTitle
                color={Clusters.COLORS[Clusters.SERVICE_PROFESSIONAL]}
              >
                {Clusters.NAMES[Clusters.SERVICE_PROFESSIONAL].toUpperCase()}
              </ClusterTitle>
              &nbsp;{Clusters.DESCRIPTIONS[Clusters.SERVICE_PROFESSIONAL]}
            </P>
            <P>
              <ClusterTitle
                color={Clusters.COLORS[Clusters.SERVICE_SKILLED]}
              >
                {Clusters.NAMES[Clusters.SERVICE_SKILLED].toUpperCase()}
              </ClusterTitle>
              &nbsp;{Clusters.DESCRIPTIONS[Clusters.SERVICE_SKILLED]}
            </P>
          </ClusterDetails>
        </ClusterContainer>
      </WheelContainer>
      <P>
        These careers are grouped into 8 primary career clusters. Some are
        further grouped into sub-clusters. &quot;Professional&quot; sub-clusters contain
        positions that typically require a bachelor’s degree or higher. &quot;Skilled&quot;
        sub-clusters contain positions that may not require a college degree.
      </P>
      <P>
        Across the next few pages, our algorithm has assembled personalized
        insights based on your responses, all culminating in the determination
        of the three career clusters that best match your Values, Interests, and
        Abilities. Then, you will be able to browse jobs within each of your top
        clusters, select your favorites, and rank them. Afterwards, you will
        unlock the Succeed phase where you can access job postings and
        additional information about your favorite careers.
      </P>
      <ButtonRow>
        <ButtonContainer>
          <Button to="/dashboard/examinee/discover/interests">Next</Button>
          <Link to="/dashboard/examinee/discover/fullgraph" link>Skip Detailed Results</Link>
        </ButtonContainer>
      </ButtonRow>
    </Content>
  );
};

export default DiscoverWelcome;
