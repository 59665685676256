import { cloneDeep, includes } from 'lodash';
import { DateTime } from 'luxon';
import moment from 'moment';

import {
  CREATE_EXAMINEES_REQUEST,
  CREATE_EXAMINEES_SUCCESS,
  SWITCH_TO_EXAMINEE_SUCCESS,
  LOAD_EXAMINEE_REQUEST,
  LOAD_EXAMINEE_SUCCESS,
  UPDATE_EXAMINEE_REQUEST,
  UPDATE_EXAMINEE_SUCCESS,
  DELETE_EXAMINEE_SUCCESS,
  DELETE_EXAMINEES_SUCCESS,
} from '../actions/examineeActions';

import {
  EXAMINEE_FEED_REQUEST,
  EXAMINEE_FEED_SUCCESS,
  EXAMINEE_FEED_ERROR,
} from '../actions/adminActions';

import {
  MOVE_ASSIGNED_SUCCESS,
  DELETE_SUPER_ASSIGNED_SUCCESS,
} from '../actions/assignedProductActions';

const examineeReducer = (
  state = {
    list: [],
    total: 0,
    loading: undefined,
    examinee: undefined,
  },
  action,
) => {
  const newState = cloneDeep(state);
  switch (action.type) {
    case EXAMINEE_FEED_REQUEST: {
      newState.loading = true;
      return newState;
    }
    case EXAMINEE_FEED_SUCCESS: {
      newState.list = [];
      action.payload.list.forEach((e) => {
        newState.list.push({
          ...e,
          updatedAt: DateTime.fromISO(e.updatedAt),
          createdAt: DateTime.fromISO(e.createdAt),
        });
      });
      newState.loading = false;
      newState.total = action.payload.total;
      return newState;
    }
    case EXAMINEE_FEED_ERROR: {
      newState.list = [];
      newState.loading = false;
      return newState;
    }
    case DELETE_SUPER_ASSIGNED_SUCCESS: {
      newState.list = newState.list.filter((e) => (
        !includes(action.payload, e.assignedProductId)
      ));
      return newState;
    }
    case CREATE_EXAMINEES_REQUEST: {
      newState.loading = true;
      return newState;
    }
    case CREATE_EXAMINEES_SUCCESS: {
      newState.loading = false;
      // TODO: Create the examinees
      return newState;
    }
    case SWITCH_TO_EXAMINEE_SUCCESS: {
      window.localStorage.clear();
      window.location.href = '/';
      return newState;
    }
    case LOAD_EXAMINEE_REQUEST: {
      newState.loading = true;
      newState.examinee = undefined;
      return newState;
    }
    case LOAD_EXAMINEE_SUCCESS: {
      newState.loading = false;
      newState.examinee = {
        ...action.payload.user,
        dob: action.payload.user.dob ? moment(action.payload.user.dob) : undefined,
      };
      return newState;
    }
    case UPDATE_EXAMINEE_REQUEST: {
      newState.loading = true;
      return newState;
    }
    case UPDATE_EXAMINEE_SUCCESS: {
      newState.loading = false;
      newState.examinee = {
        ...action.payload,
        dob: action.payload.dob ? moment(action.payload.dob) : undefined,
      };
      return newState;
    }
    case DELETE_EXAMINEE_SUCCESS: {
      newState.list = newState.list.filter((e) => e.userId !== action.payload);
      return newState;
    }
    case DELETE_EXAMINEES_SUCCESS: {
      newState.list = newState.list.filter((e) => !action.payload.includes(e.userId));
      return newState;
    }
    case MOVE_ASSIGNED_SUCCESS: {
      newState.list = newState.list.map((e) => {
        const src = action.payload.sources.find((source) => (
          source.assignedProductId === e.assignedProductId
        ));
        if (src) {
          e.groupId = action.payload.group.groupId;
          e.groupName = action.payload.group.name;
          e.groupDescription = action.payload.group.description;
          e.userGroupId = src.userGroupId;
        }
        return e;
      });
      return newState;
    }
    default: {
      return newState;
    }
  }
};

export default examineeReducer;
