export const SHORT = {
  SCIENCE: 0,
  TECHNOLOGY: 1,
  OUTDOOR: 2,
  BUSINESS: 3,
  CLERICAL: 4,
  COMMUNICATION: 5,
  ARTS: 6,
  SERVICE: 7,
};

export const SCIENCE_PROFESSIONAL = 0;
export const SCIENCE_SKILLED = 1;
export const TECHNOLOGY_PROFESSIONAL = 2;
export const TECHNOLOGY_SKILLED = 3;
export const CONSUMER_ECONOMICS = 4;
export const OUTDOOR = 5;
export const BUSINESS_PROFESSIONAL = 6;
export const BUSINESS_SKILLED = 7;
export const CLERICAL = 8;
export const COMMUNICATION = 9;
export const ARTS_PROFESSIONAL = 10;
export const ARTS_SKILLED = 11;
export const SERVICE_PROFESSIONAL = 12;
export const SERVICE_SKILLED = 13;

export const NAMES = [
  'Science Professional',
  'Science Skilled',
  'Technology Professional',
  'Technology Skilled',
  'Consumer Economics',
  'Outdoor',
  'Business Professional',
  'Business Skilled',
  'Clerical',
  'Communication',
  'Arts Professional',
  'Arts Skilled',
  'Service Professional',
  'Service Skilled',
];

export const COLORS = [
  '#d7c724', //SciencePro
  '#d7c724', //ScienceSkilled
  '#76bc21', //TechPro
  '#76bc21', //TechSkilled
  '#76bc21', //ConsumerEcon
  '#3bbfbb', //outdoor
  '#3a8dde', //Business Pro
  '#3a8dde', //Business Skilled
  '#464ea3', //clerical
  '#bc3b4a', //communication
  '#ff5440', //ArtsPro
  '#ff5440', //ArtsSkilled
  '#ff9e18', //ServicePro
  '#ff9e18', //ServiceSkilled
];

export const DESCRIPTIONS = [
  'careers involve planning and conducting research within applied mathematics, medicine, physical sciences, and other STEM-related fields.',
  'careers generally involve assisting with laboratory research or operating technical facilities and equipment.',
  'careers involve the engineering and design of vehicles, buildings and infrastructure, machines, and computer software.',
  'careers involve working with one’s hands in a skilled trade. Construction, manufacturing, installation, and maintenance-related occupations fall under this cluster.',
  'careers involve the preparation and packaging of foods and beverages, or the production of clothing and other textile products.',
  'careers are typically performed outside. Occupations include tending to animals, managing plants/flora, and cultivating and gathering crops and other natural resources.',
  'careers generally involve positions of high responsibility related to the administration, organization, and efficient functioning of businesses and government bureaus. Occupations include accounting, management, and business promotion.',
  'careers involve sales, promotion, and marketing. They also include financial and organizational business activities related to business promotion.',
  'careers involve the recording, posting, and filing of business records. Most positions require high levels of attention to detail, accuracy, orderliness, and speed.',
  'careers involve language skills associated with the written and oral communication of knowledge and ideas.',
  'careers are centered around the expression of creative talent, including positions in design, fine art, and the performing arts.',
  'careers involve the application of artistic skill and sensibility in fields such as photography, graphic arts, and design.',
  'careers involve positions of high responsibility in caring for the personal needs and welfare of others in fields such as social service, health, and education.',
  'careers involve providing service to support the tastes, desires, and welfare of others in the fields of personal, social, or health related services. These careers can also include providing protection and transportation.',
];
