import * as Colors from './components/Colors';
import * as Icons from './components/icons';
import * as StyledTable from './components/Table';
import * as Clusters from './components/Clusters';

import GroupValidator from './validators/Group';
import { UsernameSchema, UniqueUsernameSchema } from './validators/Username';
import { OptionalEmailSchema, RequiredEmailSchema, UniqueEmailSchema } from './validators/Email';
import { PasswordSchema, MatchPasswordSchema } from './validators/Password';

const validators = {
  group: GroupValidator,
  username: {
    required: UsernameSchema,
    unqiue: UniqueUsernameSchema,
  },
  email: {
    optional: OptionalEmailSchema,
    required: RequiredEmailSchema,
    unique: UniqueEmailSchema,
  },
  password: {
    required: PasswordSchema,
    match: MatchPasswordSchema,
  },
};

export { validators };

export { StyledTable };
export { Colors };
export { Clusters };
export { Icons };
export { default as Banner, KeyWrapper } from './components/Banner';
export {
  default as Button,
  LargeButton,
  MediumButton,
  SmallButton,
  FinishButton,
  StartButton,
  XButton,
  ClearButton,
} from './components/Button';
export {
  default as ClusterButton,
  ClusterContainer,
} from './components/ClusterButton';
export { default as CenteredText } from './components/CenteredText';
export { default as Fade } from './components/Fade';
export { H1, H2, H1Template } from './components/Headers';
export { default as NoRoute } from './components/NoRoute';
export { default as ScreenReader } from './components/ScreenReader';
export { default as Spinner, CenteredSpinner } from './components/Spinner';
export { default as ViaLogo } from './components/ViaLogo';
export { default as Wrapper } from './containers/Wrapper';
export { default as Content } from './components/Content';
export { default as ApiImage } from './components/ApiImage';
export { default as ImageCache } from './components/ImageCache';
export { default as Timer } from './components/Timer';
export { default as RenderMath } from './components/Math';
export { default as ExampleAnswer } from './components/AnswerDiv';
export { default as HR } from './components/Hr';
export { default as CareerWheel } from './components/CareerWheel';
export { default as UpperCaseMessage } from './components/UpperCaseMessage';
export { default as JobDetails } from './containers/JobDetails';
export { default as Table } from './table/Table';
export { default as FilterTable } from './table/FilterTable';
export { default as Filter, FilterRow } from './table/Filter';
export { default as FilterDate } from './table/FilterDate';
export { default as ExamineeMenu } from './containers/ExamineeMenu';

export {
  ScrollToTop,
  ScrollToSelf,
} from './components/Scroll';

export { default as Select } from './formComponents/Select';
export { default as InputGender } from './formComponents/Gender';
export { default as InputDOB } from './formComponents/DOB';
export { default as InputGrade } from './formComponents/Grade';
export { default as FormError } from './formComponents/FormError';
export { default as FormikError } from './formComponents/FormikError';
export {
  default as InputUsername,
} from './formComponents/Username';
//backwards compatibility
export {
  UsernameSchema,
  UniqueUsernameSchema,
};
export {
  default as InputEmail,
} from './formComponents/Email';
//backwards compatibility
export {
  OptionalEmailSchema,
  RequiredEmailSchema,
  UniqueEmailSchema,
};
export { default as CountryPicker } from './formComponents/CountryPicker';
export {
  default as Checkbox,
} from './formComponents/Checkbox';

export {
  StepperContext,
  StepperProvider,
} from './components/StepperContext';
export {
  StyledStepCircle as StepCircle,
  default as Stepper,
} from './components/Stepper';
export { default as Step } from './components/Step';

export { default as Typeahead } from './formComponents/Typeahead';

export { default as Strong } from './components/Strong';
export { default as B } from './components/B';

export { default as AbilityCareerProfile } from './components/AbilityCareerProfile';
export { default as AbilityCareerProfileTable } from './components/AbilityCareerProfileTable';
export { default as AbilityAccuracy } from './components/AbilityAccuracy';

/* eslint-disable-next-line import/first */
import {
  PermissionsToString,
  PermissionSelector,
  RECOMMENDED,
  FULL,
  VIEW,
  EMPTY,
  FULL_STRING,
  VIEW_STRING,
  EMPTY_STRING,
  CUSTOM_STRING,
  RECOMMENDED_STRING,
  cleanPermissions,
} from './formComponents/AdminPermissions';

export const permissions = {
  recommended: RECOMMENDED,
  full: FULL,
  view: VIEW,
  empty: EMPTY,
};
export const permissionStrings = {
  recommended: RECOMMENDED_STRING,
  full: FULL_STRING,
  view: VIEW_STRING,
  empty: EMPTY_STRING,
  custom: CUSTOM_STRING,
};
export {
  PermissionsToString,
  PermissionSelector,
  cleanPermissions,
};

export {
  toGradeString,
  toGenderString,
} from './components/toStrings';

export {
  default as useEditProfile,
} from './hooks/useEditProfile';

export {
  default as Modal,
} from './components/Modal';
export {
  default as ConfirmModal,
} from './components/ConfirmModal';

export {
  default as SwitchToExamineeModal,
} from './components/SwitchToExamineeModal';

export {
  default as SelectAssignedProduct,
} from './components/SelectAssignedProduct';

export { default as ValuesGraph } from './graphs/ValuesGraph';
export { default as InterestsGraph } from './graphs/InterestsGraph';
export { default as AbilitiesGraph } from './graphs/AbilitiesGraph';
export { default as FullGraph } from './graphs/FullGraph';
export { default as GraphSorterSelector } from './graphs/GraphSorterSelector';
