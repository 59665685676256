import React, { useEffect, useState } from 'react';
//import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';

import {
  FullGraph,
  MediumButton as Button,
  Colors,
  CareerWheel,
  GraphSorterSelector,
} from '../../components';

import {
  useResize,
} from '../../utils/sizing';

const P = styled.p`
  margin: 1rem 1rem 1rem 1rem;
`;

const Content = styled.div`
  border-radius: 15px; 
  background-color: ${Colors.white};
  border: 1px solid ${Colors.grey};
  min-width: 500px;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 1rem;
`;

const WheelContainer = styled.div`
  display: flex;
  ${(props) => (props.animate ? css`flex: auto` : css`flex: 0`)};
  flex-direction: row;
`;

const StyledCareerWheel = styled(CareerWheel)`
  margin: auto;
`;

const ClusterContainer = styled.div`
  display: flex;
  ${(props) => (props.animate ? css`flex: auto; flex-basis:50%;` : css`flex: 0;`)}
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  max-width: 50%;
  transition: flex 2s ease-out;
`;

const fadeIn = keyframes`
  from {
    transform: scale(.25);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const ClusterDetails = styled.p`
  ${(props) => {
    if (props.animate) {
      return css`animation: ${fadeIn} 1s linear;`;
    }
    return css`display: none;`;
  }}
  color: ${(props) => (props.color)};
  overflow: hidden;
  max-height: 600px;
  font-weight: bold;
`;

const GraphContainer = styled.div`
  ${(props) => {
    if (props.animate) {
      return css`animation: ${fadeIn} 1s linear;`;
    }
    return css`display: none;`;
  }}
`;

const ButtonContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: space-between
`;

const DiscoverCareerWheel = ({
  rankedClusters,
  results,
}) => {
  const ref = React.useRef();
  const [ width ] = useResize(ref);
  const [ animationCount, setAnimationCount ] = useState(0);
  const [ showGraph, setShowGraph ] = useState(false);
  useEffect(() => {
    const timerHandle = setInterval(() => {
      setAnimationCount(animationCount + 1);
    }, animationCount > 1 ? 1000 : 2000);
    if (animationCount > 4) {
      clearInterval(timerHandle);
    }
    return () => {
      clearInterval(timerHandle);
    };
  }, [animationCount]);
  return (
    <Content
      ref={ref}
    >
      <P>
        Now that we have looked at your Values, Interests, and Abilities
        separately, it is time to combine your scores.
      </P>
      <P>
        Based on your results, your top three Career Clusters are:
      </P>
      <WheelContainer
        animate={animationCount === 0}
      >
        <StyledCareerWheel
          height="600px"
          rankedClusters={animationCount > 0 ? rankedClusters : undefined}
          spin={animationCount === 0}
          width={width}
        />
        <ClusterContainer
          animate={animationCount > 0}
        >
          {rankedClusters.slice(0, 3).map((cluster, index) => (
            <ClusterDetails
              key={cluster.id}
              animate={animationCount > 0}
              color={cluster.color}
            >
              {index + 1}. {cluster.name.toUpperCase()}
            </ClusterDetails>
          ))}
        </ClusterContainer>
      </WheelContainer>
      <GraphContainer
        animate={animationCount > 1}
      >
        <P>
          The graph below represents your combined scores. Blue represents your
          Interests, red represents your Values, and green represents your
          Abilities. If any colors are missing within your top three, we encourage
          you to explore other clusters!
        </P>
        {
          animationCount > 2
          && !showGraph
          && (
            <FullGraph
              clusters={rankedClusters.slice(0, 3)}
              clusterMax={results.clusters.max}
              width={width}
              showAxis
            />
          )
        }
        {
          animationCount > 2
          && showGraph
          && (
            <GraphSorterSelector
              results={rankedClusters}
              sortBy={[ 'total' ]}
              render={({ rankedResults }) => (
                <FullGraph
                  clusters={rankedResults}
                  clusterMax={results.clusters.max}
                  width={width}
                  showAxis
                />
              )}
            />

          )
        }
        <Button
          style={{
            display: 'flex',
          }}
          onClick={() => setShowGraph(!showGraph)}
          link
        >
          {showGraph ? ' - Hide All Clusters' : ' + Show All Clusters' }
        </Button>
      </GraphContainer>
      <ButtonContainer>
        <Button
          to="/dashboard/examinee/discover/abilityprofile"
        >
          Back
        </Button>
        <Button
          to="/dashboard/examinee/discover/cluster/0"
        >
          Next
        </Button>
      </ButtonContainer>
    </Content>
  );
};

export default DiscoverCareerWheel;
