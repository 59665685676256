import React, { useState, useEffect } from 'react';
import { chain } from 'lodash';

import Select from '../formComponents/Select';

const Graph = ({
  results,
  sortBy = [ 'norm' ],
  /* function receives rankedResults as first param */
  render,
}) => {
  const [ sort, setSort ] = useState('high');
  const [rankedResults, setRankedResults] = useState(results);

  useEffect(() => {
    if (!results) {
      return;
    }
    let rnkd;
    switch (sort) {
      case 'high': {
        rnkd = chain(results)
          .sortBy(sortBy)
          .reverse()
          .value();
        break;
      }
      case 'low': {
        rnkd = chain(results)
          .sortBy(sortBy)
          .value();
        break;
      }
      case 'clusters': {
        rnkd = chain(results)
          .sortBy([ 'id' ])
          .value();
        break;
      }
      default: {
        // eslint-disable-next-line no-console
        console.log(`unkown sort ${sort}`);
        break;
      }
    }
    setRankedResults(rnkd);
  }, [ sort, results, setRankedResults ]);

  if (results.length === 0) {
    return (
      <p>Assessments are incomplete or not given.</p>
    );
  }

  return (
    <>
      <label
        style={{
        }}
        htmlFor="sort"
      >
        Filter Results
      </label>
      <Select
        id="sort"
        onChange={(e) => {
          setSort(e.target.value);
        }}
        style={{
          marginLeft: '0.2rem',
        }}
      >
        <option value="high">High to Low</option>
        <option value="low">Low to High</option>
        <option value="clusters">Clusters</option>
      </Select>
      {
        render({
          rankedResults,
        })
      }
    </>
  );
};

export default Graph;
