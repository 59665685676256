import React, { useRef } from 'react';
import styled from 'styled-components';
import { chain } from 'lodash';

import {
  Colors,
  AbilitiesGraph,
  MediumButton as Button,
  ClearButton,
} from '../../components';

import {
  useResize,
} from '../../utils/sizing';

import GraphTitle from './GraphTitle';

const GraphContainerDiv = styled.div`
  border-radius: 15px; 
  background-color: ${Colors.white};
  border: 1px solid ${Colors.grey};
  min-width: 500px;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 1rem 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;
`;

const AbilitiesList = styled.ul`
  list-style-type: none;
  margin: 0rem 1rem;
`;

const AbilitiesLi = styled.li`
  margin: .25rem 0;
`;

const AbilitiesP = styled.p`
  margin: 0rem 0rem;
`;

const P = styled.p`
  margin: 1rem 3rem;
`;

const AbilitiesText = [
  'Mechanical Reasoning measures how well you can understand mechanical principles and the laws of physics. This ability is important in courses related to industrial arts, Technology-related occupations, and Science, Skilled occupations.',
  'Spatial Relations measures how well you can visualize or think in three dimensions and can mentally picture the position of objects from a diagram or drawing. This ability is important for careers in the visual arts, in addition to careers that involve graphing and drafting in engineering or other related fields.',
  'Verbal Reasoning measures how well you can draw conclusions from a set of words, as well as your facility for understanding concepts expressed through text. This ability is important for broad academic success and for any occupation heavily emphasizing written or oral communication, including positions under the Communication or any Professional-level clusters.',
  'Numerical Ability measures how well you can reason with numbers to work through quantitative ideas and problems. This is particularly important for occupations in the Science, Technology, Business, and Clerical clusters.',
  'Language Usage measures your ability to recognize and utilize standard English grammar, punctuation, and capitalization. This ability is important to any job requiring written or oral communication, but is crucial to positions within the Communication, Clerical, Business, Service, and Science, Professional clusters.',
  'Word Knowledge measures how well you can understand the meaning and precise use of English words. In short, it is measuring your vocabulary. Word knowledge is important for occupations within the Communication and Professional-level clusters.',
  'Perceptual Speed and Accuracy measures how well and how fast you can notice small details in text. This ability is particularly important in Clerical positions and any other occupations that require fine visual discrimination.',
  'Manual Speed and Dexterity measures how well you can make rapid and accurate movements with your hands. This ability is important for Arts, Skilled and Technology, Skilled occupations, and any other occupation where you extensively work with your hands.',
];

/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
const AbilityList = ({ arr, showDescription, setShowDescription }) => {
  if (arr.length === 0) {
    return <P style={{ margin: '.25rem 0rem;' }}>No abilities found</P>;
  }
  return (
    <AbilitiesList>
      {
        arr.map((a) => (
          <AbilitiesLi key={`strong_${a.id}`}>
            <ClearButton
              style={{
                fontWeight: 'bold',
              }}
              onClick={() => {
                showDescription[a.ranking - 1] = !showDescription[a.ranking - 1];
                setShowDescription([...showDescription]);
              }}
            >
              {showDescription[a.ranking - 1] ? '-' : '+'} {a.name}
            </ClearButton>
            <AbilitiesP>
              {
                showDescription[a.ranking - 1]
                  ? AbilitiesText[a.ranking - 1]
                  : ''
              }
            </AbilitiesP>
          </AbilitiesLi>
        ))
      }
    </AbilitiesList>
  );
};
/* eslint-enable no-param-reassign */
/* eslint-enable react/destructuring-assignment */

const DiscoverAbilities = ({
  abilities,
}) => {
  const ref = useRef();
  const [ width ] = useResize(ref);
  const [ showDescription, setShowDescription ] = React.useState([]);
  const strong = chain(abilities.ability)
    .filter((a) => (a.norm >= 7))
    .value();
  const mid = chain(abilities.ability)
    .filter((a) => (a.norm >= 4 && a.norm < 7))
    .value();
  const weak = chain(abilities.ability)
    .filter((a) => (a.norm < 4))
    .value();

  return (
    <GraphContainerDiv
      ref={ref}
    >
      <GraphTitle active="abilities" />
      <AbilitiesGraph
        abilities={abilities}
        width={width}
      />
      <P>
        Your Abilities scores have been plotted above. Each number represents your predicted
        ability compared to people at your educational level who have completed this test.
        Scores from 1-3 indicate below average ability, scores from 4-6 indicate average
        ability, and scores from 7-9 indicate above average ability.
      </P>
      <P>
        These scores represent <i>estimates of potential!</i> They are here to help you
        identify your baseline strengths and weaknesses at this point in time. A
        high score on one Ability does not guarantee excellence, but indicates
        that work tasks and training related to that scale may come easier to
        you. Conversely, a lower score does not guarantee failure, but indicates
        that you may need to put in more effort to succeed in that area.
      </P>
      <P>
        Your responses indicate a potential for high proficiency in the following areas:
      </P>
      <AbilityList
        arr={strong}
        showDescription={showDescription}
        setShowDescription={setShowDescription}
      />
      <P>
        Your responses indicate average proficiency in the following areas:
      </P>
      <AbilityList
        arr={mid}
        showDescription={showDescription}
        setShowDescription={setShowDescription}
      />
      <P>
        Your responses indicate that you may need some extra training in the following areas:
      </P>
      <AbilityList
        arr={weak}
        showDescription={showDescription}
        setShowDescription={setShowDescription}
      />
      <ButtonContainer>
        <Button to="/dashboard/examinee/discover/values">
          Back
        </Button>
        <Button to="/dashboard/examinee/discover/abilityprofile">
          Next
        </Button>
      </ButtonContainer>
    </GraphContainerDiv>
  );
};

export default DiscoverAbilities;
