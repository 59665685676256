/* eslint max-len:0 */
import React, { useRef } from 'react';
import styled from 'styled-components';

import { chain } from 'lodash';

import {
  Colors,
  InterestsGraph,
  MediumButton as Button,
  Clusters,
} from '../../components';

import {
  useResize,
} from '../../utils/sizing';

import GraphTitle from './GraphTitle';

const GraphContainerDiv = styled.div`
  border-radius: 15px; 
  background-color: ${Colors.white};
  border: 1px solid ${Colors.grey};
  min-width: 500px;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 1rem 0;
`;

const BlueText = styled.span`
  color: ${Colors.blue};
  font-weight: bold;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;
`;

const P = styled.p`
  margin: 0.5rem 1.5rem;
`;

const DiscoverInterests = ({
  interests,
}) => {
  const ref = useRef();
  const [ width ] = useResize(ref);
  const top3 = chain(interests)
    .sortBy((a) => (a.norm))
    .reverse()
    .take(3)
    .value();

  return (
    <GraphContainerDiv
      ref={ref}
    >
      <GraphTitle active="interests" />
      <P>Your top three interests areas are:</P>
      <P><BlueText>{top3[0].name.toUpperCase()}</BlueText> {Clusters.DESCRIPTIONS[top3[0].id - 1]}</P>
      <P><BlueText>{top3[1].name.toUpperCase()}</BlueText> {Clusters.DESCRIPTIONS[top3[1].id - 1]}</P>
      <P><BlueText>{top3[2].name.toUpperCase()}</BlueText> {Clusters.DESCRIPTIONS[top3[2].id - 1]}</P>
      <InterestsGraph
        interests={interests}
        width={width}
      />
      <P>
        A percentile score is printed inside each bar. These scores approximate
        your position on each scale as compared to other people at your
        educational level. If your score is near 50, about half (50%) of others
        fall below you. The higher your score, the greater your interest is
        compared to others.
      </P>
      <P>
        You may find you have four or five scores that are quite close to each
        other. That just means you are interested in a wide variety of careers!
        Don&apos;t be afraid to explore beyond your top three clusters.
      </P>
      <ButtonContainer>
        <Button to="/dashboard/examinee/discover/">
          Back
        </Button>
        <Button to="/dashboard/examinee/discover/values">
          Next
        </Button>
      </ButtonContainer>
    </GraphContainerDiv>
  );
};

export default DiscoverInterests;
