/* eslint quotes:0, react/no-danger:0 */
/**
 * The SVG Career Wheel component.
 */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';

import * as Colors from './Colors';
import { useResize } from '../../utils/sizing';
import * as Clusters from './Clusters';

const clusterColors = Clusters.COLORS;

const clusterColorsAccents = [
  '#a89532', //SciencePro
  '#a89532', //ScienceSkilled
  '#69953f', //TechPro
  '#69953f', //TechSkilled
  '#69953f', //ConsumerEcon
  '#158e9b', //outdoor
  '#2076a5', // Business Pro
  '#2076a5', // Business Skilled
  '#313c80', // clerical
  '#992438', // communication
  '#b53427', //ArtsPro
  '#b53427', //ArtsSkilled
  '#bd7329', //ServicePro
  '#bd7329', //ServiceSkilled
];

const {
  SHORT: {
    SCIENCE,
    TECHNOLOGY,
    OUTDOOR,
    BUSINESS,
    CLERICAL,
    COMMUNICATION,
    ARTS,
    SERVICE,
  },
} = Clusters;

/** Sets the career wheel colors based on the 14 career cluster input */
/* eslint-disable no-param-reassign */
const computeClusterColors = (cluster, computedColors) => {
  const pos = cluster - 1;
  switch (cluster) {
    case 1:
    case 2:
      computedColors[SCIENCE].color = clusterColors[pos];
      computedColors[SCIENCE].accent = clusterColorsAccents[pos];
      break;
    case 3:
    case 4:
    case 5:
      computedColors[TECHNOLOGY].color = clusterColors[pos];
      computedColors[TECHNOLOGY].accent = clusterColorsAccents[pos];
      break;
    case 6:
      computedColors[OUTDOOR].color = clusterColors[pos];
      computedColors[OUTDOOR].accent = clusterColorsAccents[pos];
      break;
    case 7:
    case 8:
      computedColors[BUSINESS].color = clusterColors[pos];
      computedColors[BUSINESS].accent = clusterColorsAccents[pos];
      break;
    case 9:
      computedColors[CLERICAL].color = clusterColors[pos];
      computedColors[CLERICAL].accent = clusterColorsAccents[pos];
      break;
    case 10:
      computedColors[COMMUNICATION].color = clusterColors[pos];
      computedColors[COMMUNICATION].accent = clusterColorsAccents[pos];
      break;
    case 11:
    case 12:
      computedColors[ARTS].color = clusterColors[pos];
      computedColors[ARTS].accent = clusterColorsAccents[pos];
      break;
    case 13:
    case 14:
      computedColors[SERVICE].color = clusterColors[pos];
      computedColors[SERVICE].accent = clusterColorsAccents[pos];
      break;
    default:
      break;
  }
};
/* eslint-enable no-param-reassign */

const Isolate = styled.g`
  isolation: isolate;
`;

const ClusterTitle = styled.text`
  fill: #fff;
  font-size: 25.5px;
  font-family: Heebo-Bold, Heebo;
  font-weight: 700;
  filter: drop-shadow(0 0 6px grey);
  ${(props) => (props.showPointer ? 'cursor: pointer;' : 'cursor: default;')}
`;

const SubCluster = styled.tspan`
  fill: #fff;
  font-size: 20.4px;
  font-family: Heebo-Medium, Heebo;
  font-weight: 500;
`;

const GreyRing = styled.circle`
  fill: none;
  stroke: #616969;
  stroke-miterlimit: 10;
  stroke-width: 16px;
`;

const WhiteRing = styled.circle`
  fill: #fff;
`;

const Title = styled.text`
  font-size: 40px;
  fill: #616969;
  font-family: Heebo-ExtraBold, Heebo;
  font-weight: 800;
`;

const Path = styled.path`
  ${(props) => (props.showPointer ? 'cursor: pointer;' : '')}
`;

const INITIAL_COLORS = [
  {
    color: clusterColors[0],
    accent: clusterColorsAccents[0],
  },
  {
    color: clusterColors[2],
    accent: clusterColorsAccents[2],
  },
  {
    color: clusterColors[5],
    accent: clusterColorsAccents[5],
  },
  {
    color: clusterColors[6],
    accent: clusterColorsAccents[6],
  },
  {
    color: clusterColors[8],
    accent: clusterColorsAccents[8],
  },
  {
    color: clusterColors[9],
    accent: clusterColorsAccents[9],
  },
  {
    color: clusterColors[10],
    accent: clusterColorsAccents[10],
  },
  {
    color: clusterColors[12],
    accent: clusterColorsAccents[12],
  },
];

/** The SVG VIA Logo */
const CareerWheel = ({
  type,
  rankedClusters,
  onClusterClick,
  spin,
  fadeDuration,
  ...rest
}) => {
  const onClickCluster = (cluster) => {
    if (onClusterClick) {
      onClusterClick(cluster);
    }
  };
  const refColors = [
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ];
  const refAccents = [
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ];
  const spinRef = React.createRef();
  const [ width, height ] = useResize(spinRef);
  const [computedColors, setComputedColors] = useState(cloneDeep(INITIAL_COLORS));
  const [spinXY, setSpinXY] = useState({});
  useEffect(() => {
    if (rankedClusters) {
      computedColors.forEach((cluster) => {
        /* eslint-disable no-param-reassign */
        cluster.color = Colors.darkGrey;
        cluster.accent = Colors.grey;
        /* eslint-enable no-param-reassign */
      });
      computeClusterColors(rankedClusters[0]?.ranking, computedColors);
      computeClusterColors(rankedClusters[1]?.ranking, computedColors);
      computeClusterColors(rankedClusters[2]?.ranking, computedColors);
      setComputedColors(cloneDeep(computedColors));
    } else {
      setComputedColors(cloneDeep(INITIAL_COLORS));
    }
  }, [rankedClusters, type]);
  useEffect(() => {
    refColors.forEach((ref) => {
      ref.current.beginElement();
    });
    refAccents.forEach((ref) => {
      ref.current.beginElement();
    });
  }, [computedColors]);
  useEffect(() => {
    const bb = spinRef.current.getBBox();
    const cx = bb.x + bb.width / 2;
    const cy = bb.y + bb.height / 2;
    setSpinXY({ cx, cy });
  }, [width, height]);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 887.86 840.01"
      {...rest}
    >
      <defs>
        <linearGradient id="linear-gradient" x1="1247.97" y1="-1023.89" x2="1456.57" y2="-815.29" gradientTransform="translate(-470.93 -933.58) rotate(90.07)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#fff" />
          <stop offset="0.38" stopColor="#f3f3f3" />
          <stop offset="0.73" stopColor="#e4e4e4" />
        </linearGradient>
      </defs>
      <Isolate>
        <g ref={spinRef}>
          {
            spin && (
            <animateTransform
              from={`0 ${spinXY.cx} ${spinXY.cy}`}
              to={`360 ${spinXY.cx} ${spinXY.cy}`}
              dur="1s"
              type="rotate"
              repeatCount="indefinite"
              attributeName="transform"
              attributeType="XML"
            />
            )
          }
          <Path
            onClick={() => onClickCluster(SERVICE)}
            showPointer={onClusterClick}
            d="M824.34,254.62,617.34,340h0l-25.73-4h0L559.69,349.2h0A163.5,163.5,0,0,0,518,307.37l13.23-31.85-3.94-25.76,0,0,86-206.79A442.1,442.1,0,0,1,824.34,254.62Z"
          >
            <animate ref={refColors[SERVICE]} fill="freeze" attributeName="fill" values={`${clusterColors[12]};${computedColors[SERVICE].color}`} dur={fadeDuration} />
          </Path>
          <Path
            onClick={() => onClickCluster(SERVICE)}
            showPointer={onClusterClick}
            d="M579.06,288.14a216.46,216.46,0,0,0-51.8-38.42l0,0,3.94,25.76a197.86,197.86,0,0,1,60.39,60.53l25.73,4h0A216.27,216.27,0,0,0,579.06,288.14Z"
          >
            <animate ref={refAccents[SERVICE]} fill="freeze" attributeName="fill" values={`${clusterColorsAccents[12]};${computedColors[SERVICE].accent}`} dur={fadeDuration} />
          </Path>
          <Path
            onClick={() => onClickCluster(ARTS)}
            showPointer={onClusterClick}
            d="M596.92,36.14,511,242.91v0l-21,15.36,0,0-13.22,31.82h0a163.54,163.54,0,0,0-59.08-.06l-13.16-31.87-21-15.44,0,0L298,35.74a442.09,442.09,0,0,1,299,.4Z"
          >
            <animate ref={refColors[ARTS]} fill="freeze" attributeName="fill" values={`${clusterColors[10]};${computedColors[ARTS].color}`} dur={fadeDuration} />
          </Path>
          <Path
            onClick={() => onClickCluster(ARTS)}
            showPointer={onClusterClick}
            d="M447.19,233.28a216.75,216.75,0,0,0-63.8,9.46l0,0,21,15.44a197.8,197.8,0,0,1,85.5.09l21-15.36v0A216.51,216.51,0,0,0,447.19,233.28Z"
          >
            <animate ref={refAccents[ARTS]} fill="freeze" attributeName="fill" values={`${clusterColorsAccents[10]};${computedColors[ARTS].accent}`} dur={fadeDuration} />
          </Path>
          <Path
            onClick={() => onClickCluster(COMMUNICATION)}
            showPointer={onClusterClick}
            d="M281.62,42.46l85.42,207,0,0-4,25.74v0l13.15,31.86h0a163.5,163.5,0,0,0-41.82,41.73l-31.85-13.23-25.76,3.94,0,0-206.79-86A442.14,442.14,0,0,1,281.62,42.46Z"
          >
            <animate ref={refColors[COMMUNICATION]} fill="freeze" attributeName="fill" values={`${clusterColors[9]};${computedColors[COMMUNICATION].color}`} dur={fadeDuration} />
          </Path>
          <Path
            onClick={() => onClickCluster(COMMUNICATION)}
            showPointer={onClusterClick}
            d="M315.15,287.74a216.46,216.46,0,0,0-38.42,51.8l0,0,25.76-3.94a197.86,197.86,0,0,1,60.53-60.39l4-25.74,0,0A216.42,216.42,0,0,0,315.15,287.74Z"
          >
            <animate ref={refAccents[COMMUNICATION]} fill="freeze" attributeName="fill" values={`${clusterColorsAccents[9]};${computedColors[COMMUNICATION].accent}`} dur={fadeDuration} />
          </Path>
          <Path
            onClick={() => onClickCluster(CLERICAL)}
            showPointer={onClusterClick}
            d="M63.15,269.88l206.77,86h0l15.36,21h0l31.82,13.23v0a163.54,163.54,0,0,0-.06,59.08l-31.88,13.16-15.43,21h0l-207,85.45a442,442,0,0,1,.41-299Z"
          >
            <animate ref={refColors[CLERICAL]} fill="freeze" attributeName="fill" values={`${clusterColors[8]};${computedColors[CLERICAL].color}`} dur={fadeDuration} />
          </Path>
          <Path
            onClick={() => onClickCluster(CLERICAL)}
            showPointer={onClusterClick}
            d="M260.29,419.61a216.63,216.63,0,0,0,9.46,63.79h0l15.43-21a198,198,0,0,1,.1-85.5l-15.36-21h0A216.51,216.51,0,0,0,260.29,419.61Z"
          >
            <animate ref={refAccents[CLERICAL]} fill="freeze" attributeName="fill" values={`${clusterColorsAccents[8]};${computedColors[CLERICAL].accent}`} dur={fadeDuration} />
          </Path>
          <Path
            onClick={() => onClickCluster(BUSINESS)}
            showPointer={onClusterClick}
            d="M69.47,585.17l207-85.42h0l25.74,4h0l31.86-13.15h0a163.31,163.31,0,0,0,19,22.81,161.55,161.55,0,0,0,22.77,19l-13.23,31.85L366.56,590l0,0-86,206.79A442.1,442.1,0,0,1,69.47,585.17Z"
          >
            <animate ref={refColors[BUSINESS]} fill="freeze" attributeName="fill" values={`${clusterColors[6]};${computedColors[BUSINESS].color}`} dur={fadeDuration} />
          </Path>
          <Path
            onClick={() => onClickCluster(BUSINESS)}
            showPointer={onClusterClick}
            d="M314.75,551.65a216.61,216.61,0,0,0,51.79,38.42l0,0-3.93-25.76a197.86,197.86,0,0,1-60.39-60.53l-25.74-4h0A216.59,216.59,0,0,0,314.75,551.65Z"
          >
            <animate ref={refAccents[BUSINESS]} fill="freeze" attributeName="fill" values={`${clusterColorsAccents[6]};${computedColors[BUSINESS].accent}`} dur={fadeDuration} />
          </Path>
          <Path
            onClick={() => onClickCluster(OUTDOOR)}
            showPointer={onClusterClick}
            d="M296.89,803.65l86-206.77,0,0,21-15.36v0l13.23-31.82h0a164.42,164.42,0,0,0,29.54,2.72,162.65,162.65,0,0,0,29.55-2.66l13.15,31.87,21,15.44v0l85.45,207a442.09,442.09,0,0,1-299-.4Z"
          >
            <animate ref={refColors[OUTDOOR]} fill="freeze" attributeName="fill" values={`${clusterColors[5]};${computedColors[OUTDOOR].color}`} dur={fadeDuration} />
          </Path>
          <Path
            onClick={() => onClickCluster(OUTDOOR)}
            showPointer={onClusterClick}
            d="M446.62,606.51a216.68,216.68,0,0,0,63.79-9.46v0l-21-15.44a197.75,197.75,0,0,1-85.49-.09l-21,15.36,0,0A216.57,216.57,0,0,0,446.62,606.51Z"
          >
            <animate ref={refAccents[OUTDOOR]} fill="freeze" attributeName="fill" values={`${clusterColorsAccents[5]};${computedColors[OUTDOOR].accent}`} dur={fadeDuration} />
          </Path>
          <Path
            onClick={() => onClickCluster(TECHNOLOGY)}
            showPointer={onClusterClick}
            d="M612.18,797.33l-85.42-207,0,0,4-25.74v0L517.6,532.68h0a163.31,163.31,0,0,0,22.81-19,161.55,161.55,0,0,0,19-22.77l31.84,13.23L617,500.23l0,0,206.79,86A442.1,442.1,0,0,1,612.18,797.33Z"
          >
            <animate ref={refColors[TECHNOLOGY]} fill="freeze" attributeName="fill" values={`${clusterColors[2]};${computedColors[TECHNOLOGY].color}`} dur={fadeDuration} />
          </Path>
          <Path
            onClick={() => onClickCluster(TECHNOLOGY)}
            showPointer={onClusterClick}
            d="M578.66,552.05a216.73,216.73,0,0,0,38.42-51.8l0,0-25.77,3.94a197.68,197.68,0,0,1-60.52,60.39l-4,25.74,0,0A216.27,216.27,0,0,0,578.66,552.05Z"
          >
            <animate ref={refAccents[TECHNOLOGY]} fill="freeze" attributeName="fill" values={`${clusterColorsAccents[2]};${computedColors[TECHNOLOGY].accent}`} dur={fadeDuration} />
          </Path>
          <Path
            onClick={() => onClickCluster(SCIENCE)}
            showPointer={onClusterClick}
            d="M830.66,569.91l-206.77-86h0l-15.37-21h0l-31.82-13.23v0a163.84,163.84,0,0,0,.07-59.08l31.87-13.16,15.44-21h0l207-85.45a442.09,442.09,0,0,1-.4,299Z"
          >
            <animate ref={refColors[SCIENCE]} fill="freeze" attributeName="fill" values={`${clusterColors[0]};${computedColors[SCIENCE].color}`} dur={fadeDuration} />
          </Path>
          <Path
            onClick={() => onClickCluster(SCIENCE)}
            showPointer={onClusterClick}
            d="M633.52,420.18a216.63,216.63,0,0,0-9.46-63.79h0l-15.44,21a197.59,197.59,0,0,1-.1,85.5l15.37,21h0A216.51,216.51,0,0,0,633.52,420.18Z"
          >
            <animate ref={refAccents[SCIENCE]} fill="freeze" attributeName="fill" values={`${clusterColorsAccents[0]};${computedColors[SCIENCE].accent}`} dur={fadeDuration} />
          </Path>
          <WhiteRing cx={spinXY.cx} cy={spinXY.cy} r="147.5" />
          <GreyRing cx={spinXY.cx} cy={spinXY.cy} r="127.27" />
          {
            type === 'spanish' && (
              <ClusterTitle
                onClick={() => onClickCluster(ARTS)}
                transform="translate(408.25 98.1)"
                showPointer={onClusterClick}
              >
                ARTES
                <SubCluster>
                  <tspan x="-10.03" y="22.44">
                    Profesional
                  </tspan>
                  <tspan x="-30.65" y="44.88">
                    Sub-Profesional
                  </tspan>
                </SubCluster>
              </ClusterTitle>
            )
          }
          {
            type !== 'spanish' && (
              <ClusterTitle
                transform="translate(415.52 110.49)"
                onClick={() => onClickCluster(ARTS)}
                showPointer={onClusterClick}
              >
                ARTS
                <SubCluster>
                  <tspan x="-22.92" y="22.44">
                    Professional
                  </tspan>
                  <tspan x="3.64" y="44.88">Skilled</tspan>
                </SubCluster>
              </ClusterTitle>
            )
          }
          {
            type === 'spanish' && (
              <ClusterTitle
                transform="translate(597.58 585.41)"
                onClick={() => onClickCluster(TECHNOLOGY)}
                showPointer={onClusterClick}
              >
                TECNOLOGÍA
                <SubCluster>
                  <tspan x="-47.45" y="22.44">
                    Economica Del Consumidor
                  </tspan>
                  <tspan x="25.03" y="44.88">
                    Profesional
                  </tspan>
                  <tspan x="4.42" y="67.32">
                    Sub-Profesional
                  </tspan>
                </SubCluster>
              </ClusterTitle>
            )
          }
          {
            type !== 'spanish' && (
            <ClusterTitle
              transform="translate(585.56 615.8)"
              onClick={() => onClickCluster(TECHNOLOGY)}
              showPointer={onClusterClick}
            >
              TECHNOLOGY
              <SubCluster>
                <tspan x="-17.25" y="22.44">Consumer Economics</tspan>
                <tspan x="24.43" y="44.88">Professional</tspan>
                <tspan x="50.99" y="67.32">Skilled</tspan>
              </SubCluster>
            </ClusterTitle>
            )
          }
          {
            type === 'spanish' && (
              <ClusterTitle
                transform="translate(685.96 404.31)"
                onClick={() => onClickCluster(SCIENCE)}
                showPointer={onClusterClick}
              >
                CIENCIAS
                <SubCluster>
                  <tspan x="8.58" y="22.44">
                    Profesional
                  </tspan>
                  <tspan x="-12.03" y="44.88">
                    Sub-Profesional
                  </tspan>
                </SubCluster>
              </ClusterTitle>
            )
          }
          {
            type !== 'spanish' && (
              <ClusterTitle
                transform="translate(696.12 407.32)"
                onClick={() => onClickCluster(SCIENCE)}
                showPointer={onClusterClick}
              >
                SCIENCE
                <SubCluster>
                  <tspan x="-2.22" y="22.44">Professional</tspan>
                  <tspan x="24.34" y="44.88">Skilled</tspan>
                </SubCluster>
              </ClusterTitle>
            )
          }
          {
            type === 'spanish' && (
              <ClusterTitle
                transform="translate(377.02 713.3)"
                onClick={() => onClickCluster(OUTDOOR)}
                showPointer={onClusterClick}
              >
                AIRE LIBRE
              </ClusterTitle>
            )
          }
          {
            type !== 'spanish' && (
              <ClusterTitle
                transform="translate(383.74 725.7)"
                onClick={() => onClickCluster(OUTDOOR)}
                showPointer={onClusterClick}
              >
                OUTDOOR
              </ClusterTitle>
            )
          }
          {
            type === 'spanish' && (
              <ClusterTitle
                transform="translate(167.63 607.83)"
                onClick={() => onClickCluster(BUSINESS)}
                showPointer={onClusterClick}
              >
                NEGOCIOS
                <SubCluster>
                  <tspan x="14.16" y="22.44">
                    Profesional
                  </tspan>
                  <tspan x="-6.45" y="44.88">
                    Sub-Profesional
                  </tspan>
                </SubCluster>
              </ClusterTitle>
            )
          }
          {
            type !== 'spanish' && (
              <ClusterTitle
                transform="translate(166.49 613.22)"
                onClick={() => onClickCluster(BUSINESS)}
                showPointer={onClusterClick}
              >
                BUSINESS
                <SubCluster>
                  <tspan x="6.68" y="22.44">Professional</tspan>
                  <tspan x="33.24" y="44.88">Skilled</tspan>
                </SubCluster>
              </ClusterTitle>
            )
          }
          {
            type === 'spanish' && (
              <ClusterTitle
                transform="translate(99.65 424.04)"
                onClick={() => onClickCluster(CLERICAL)}
                showPointer={onClusterClick}
              >
                OFICINA
              </ClusterTitle>
            )
          }
          {
            type !== 'spanish' && (
              <ClusterTitle
                transform="translate(89.9 429.43)"
                onClick={() => onClickCluster(CLERICAL)}
                showPointer={onClusterClick}
              >
                CLERICAL
              </ClusterTitle>
            )
          }
          {
            type === 'spanish' && (
              <ClusterTitle
                transform="translate(112.54 220.95)"
                onClick={() => onClickCluster(COMMUNICATION)}
                showPointer={onClusterClick}
              >
                COMUNICACIONES
              </ClusterTitle>
            )
          }
          {
            type !== 'spanish' && (
              <ClusterTitle
                transform="translate(119.04 218.34)"
                onClick={() => onClickCluster(COMMUNICATION)}
                showPointer={onClusterClick}
              >
                COMMUNICATION
              </ClusterTitle>
            )
          }
          {
            type === 'spanish' && (
              <ClusterTitle
                transform="translate(597.55 190.95)"
                onClick={() => onClickCluster(SERVICE)}
                showPointer={onClusterClick}
              >
                SERVICIOS
                <SubCluster>
                  <tspan x="16.16" y="22.44">
                    Profesional
                  </tspan>
                  <tspan x="-4.46" y="44.88">
                    Sub-Profesional
                  </tspan>
                </SubCluster>
              </ClusterTitle>
            )
          }
          {
            type !== 'spanish' && (
              <ClusterTitle
                transform="translate(611.25 203.34)"
                onClick={() => onClickCluster(SERVICE)}
                showPointer={onClusterClick}
              >
                SERVICE
                <SubCluster>
                  <tspan x="-3.16" y="22.44">Professional</tspan>
                  <tspan x="23.4" y="44.88">Skilled</tspan>
                </SubCluster>
              </ClusterTitle>
            )
          }
        </g>
        <g>
          {
            type === 'spanish' && (
              <Title transform="translate(339.39 406.88)">
                GRUPOS DE
                <tspan x="1.85" y="39">CARRERAS</tspan>
              </Title>
            )
          }
          {
            type !== 'spanish' && (
              <Title transform="translate(369.99 412.88)">
                CAREER
                <tspan x="-24.44" y="39">CLUSTERS</tspan>
              </Title>
            )
          }
        </g>
      </Isolate>
    </svg>
  );
};

CareerWheel.propTypes = {
  /** The type of career wheel to display. */
  type: PropTypes.oneOf([ 'cluster', 'spanish' ]),
  /** If provided, the top three clusters will stay color and the rest will fade to grey. */
  rankedClusters: PropTypes.arrayOf(PropTypes.shape({
    ranking: PropTypes.number.isRequired,
  })),
  onClusterClick: PropTypes.func,
  /** If true, the wheel will spin. */
  spin: PropTypes.bool,
  fadeDuration: PropTypes.string,
};

CareerWheel.defaultProps = {
  type: 'cluster',
  rankedClusters: undefined,
  spin: false,
  fadeDuration: '2s',
  onClusterClick: undefined,
};

export default CareerWheel;
