import http from '../utils/httpCli';
import { dispatchWrapper as sendIt } from '../utils/shortHand';

export const LOAD_PURCHASES_REQUEST = 'LOAD_PURCHASES_REQUEST';
export const LOAD_PURCHASES_SUCCESS = 'LOAD_PURCHASES_SUCCESS';
export const LOAD_PURCHASES_ERROR = 'LOAD_PURCHASES_ERROR';

export const loadPurchases = (queryParams) => (
  (dispatch) => {
    sendIt(dispatch, LOAD_PURCHASES_REQUEST);
    return http.post('/purchases/list', queryParams).then(({ data }) => {
      sendIt(dispatch, LOAD_PURCHASES_SUCCESS, data);
      return data;
    }).catch((e) => {
      console.error(e);
      sendIt(dispatch, LOAD_PURCHASES_ERROR, e);
    });
  }
);
