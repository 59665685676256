import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as d3 from 'd3';

import {
  rem,
} from '../../utils/sizing';

import * as Colors from '../components/Colors';

const ChartLabel = styled.text`
  font-size: ${rem(1)};
  font-weight: bold;
`;

const InterestsGraphs = ({
  interests,
  showRawScores,
  width: inWidth,
}) => {
  const [labelWidth, setLabelWidth] = React.useState(282);

  let width = inWidth;
  if (width <= 0) {
    width = 500;
  }

  const margin = {
    left: rem(2) + labelWidth,
    right: rem(2),
    top: rem(1),
    bottom: rem(1),
    labelPadding: rem(0.5),
  };
  const barHeight = rem(2);
  const barPadding = 0.1;
  const height = interests.length * (barHeight + barPadding) + margin.top + margin.bottom;

  React.useEffect(() => {
    margin.left = rem(1) + labelWidth;
  }, [labelWidth]);

  const x = d3
    .scaleLinear()
    .domain([0, 100])
    .range([margin.left, width - margin.right - margin.labelPadding ]);

  const y = d3
    .scaleBand()
    .domain(interests.map((c) => (c.name)))
    .rangeRound([margin.top, height - margin.bottom])
    .padding(barPadding);

  const bottomAxis = d3
    .axisBottom(x);

  const G = interests.map((c) => {
    let delay = 0;
    const duration = 1000;
    const startWidth = x(0);
    return (
      <g
        key={`interestsBar_${c.id}`}
      >
        <ChartLabel
          x={x(0)}
          y={y(c.name)}
          dy={(barHeight + barPadding) / 2 + rem(0.25)}
          transform={`translate(${-labelWidth - margin.labelPadding},0)`}
          fill={Colors.grey}
          ref={(node) => {
            if (node) {
              const w = node.getBBox().width;
              node.transform.baseVal.getItem(0).setTranslate(-w - margin.labelPadding, 0);
              if (labelWidth < w) {
                setLabelWidth(w);
              }
            }
          }}
        >
          {showRawScores ? `${c.name.toUpperCase()} ${c.rawScore}` : c.name.toUpperCase()}
        </ChartLabel>
        <rect
          ref={(node) => {
            d3
              .select(node)
              .transition()
              .delay(delay)
              .ease(d3.easeLinear)
              .duration(duration)
              .attr('width', x(c.norm) - startWidth);
            delay += duration;
          }}
          x={startWidth}
          y={y(c.name)}
          width={0}
          height={y.bandwidth()}
          fill={Colors.blue}
        />
        <text
          x={x(c.norm)}
          y={y(c.name)}
          dy={(barHeight + barPadding) / 2 + rem(0.25)}
          ref={(node) => {
            if (node) {
              const bbox = node.getBBox();
              d3
                .select(node)
                .attr(
                  'x',
                  x(c.norm) - bbox.width,
                );
            }
          }}
          fill={Colors.white}
        >
          {c.norm}%&nbsp;
        </text>
      </g>
    );
  });

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
    >
      {G}
      <g ref={(node) => d3.select(node).call(bottomAxis)} transform={`translate(0,${height - margin.bottom})`} />
    </svg>
  );
};

InterestsGraphs.propTypes = {
  interests: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    norm: PropTypes.number.isRequired,
  })),
  showRawScores: PropTypes.bool,
  width: PropTypes.number,
};

InterestsGraphs.defaultProps = {
  interests: [],
  width: 500,
  showRawScores: false,
};

export default InterestsGraphs;
